import React, { useEffect, useState } from 'react';
import { v4 as uniqueId } from 'uuid';
import {
  Button, Empty, Pagination, Result, Skeleton
} from 'antd';
import useFetchData from '../../hooks/useFetchData';
import QueryOptions from '../shared/QueryOptions';
import EditTrajetModal from './TrajetEditModal'; // Modal for trajet editing
import { formatDateInFrench, formatMinutesToHoursAndMinutes } from '../../utils/format';

function TrajetList({ add }) {
  const [query, setQuery] = useState({
    search: '', sort: 'asce', page: '1', rows: '10'
  });
  const [fetchAgain, setFetchAgain] = useState(false);
  const [editTrajetModal, setEditTrajetModal] = useState({ open: false, trajet: null });

  // Fetch Trajet-list API data
  const [loading, error, response] = useFetchData(
    `/all-trajets-list?keyword=${query.search}&limit=${query.rows}&page=${query.page}&sort=${query.sort}`,
    fetchAgain
  );

  // Reset query options
  useEffect(() => {
    setQuery((prevState) => ({ ...prevState, page: '1' }));
  }, [query.rows, query.search, response]);

  // Open edit trajet modal
  const openEditModal = (trajet) => {
    setEditTrajetModal({ open: true, trajet });
  };

  // Close edit trajet modal
  const closeEditModal = () => {
    setEditTrajetModal({ open: false, trajet: null });
    setFetchAgain(!fetchAgain); // Refresh the list after editing
  };

  return (
    <div>
      {/* Trajet list — query section */}
      <QueryOptions query={query} setQuery={setQuery} />

      {/* Trajet list — content section */}
      <div className='w-full flex flex-row flex-wrap items-center justify-center gap-2'>
        {error ? (
          <Result
            title='Failed to fetch'
            subTitle={error}
            status='error'
          />
        ) : (
          <Skeleton loading={loading} paragraph={{ rows: 10 }} active>
            {response?.data?.rows?.length === 0 ? (
              <Empty
                className='mt-10'
                description={<span>Sorry! No data found.</span>}
              />
            ) : (
              <div className='table-layout'>
                <div className='table-layout-container'>
                  <table className='data-table'>
                    {/* Data table — head */}
                    <thead className='data-table-head'>
                      <tr className='data-table-head-tr'>
                        <th className='data-table-head-tr-th text-center'>Bus</th>
                        <th className='data-table-head-tr-th text-center'>Départ</th>
                        <th className='data-table-head-tr-th text-center'>Arrivée</th>
                        <th className='data-table-head-tr-th text-center'>Heure de départ</th>
                        <th className='data-table-head-tr-th text-center'>Temps estimé</th>
                        <th className='data-table-head-tr-th text-center'>Date de départ</th>
                        <th className='data-table-head-tr-th text-center'>Actions</th>
                      </tr>
                    </thead>

                    {/* Data table — body */}
                    <tbody>
                      {response?.data?.rows?.map((data) => (
                        <tr className='data-table-body-tr' key={data.id || uniqueId()}>
                          <td className='data-table-body-tr-td text-center'>
                            {' '}
                            <span className='font-bold'>{data?.bus.name}</span>
                            {' '}
                            [
                            {data?.bus.licensePlate}
                            ]
                          </td>
                          <td className='data-table-body-tr-td text-center'>{data?.departure?.name}</td>
                          <td className='data-table-body-tr-td text-center'>{data?.arrival?.name}</td>
                          <td className='data-table-body-tr-td text-center'>{data?.departureTime}</td>
                          <td className='data-table-body-tr-td text-center'>{formatMinutesToHoursAndMinutes(data?.estimationTime)}</td>
                          <td className='data-table-body-tr-td text-center'>{formatDateInFrench(data?.date)}</td>
                          <td className='data-table-body-tr-td text-center'>
                            <Button
                              className='inline-flex items-center !px-2'
                              onClick={() => add(data)}
                              type='link'
                              aria-label={`View trajet ${data?.bus?.name}`}
                            >
                              View
                            </Button>
                            <Button
                              className='inline-flex items-center !px-2'
                              type='primary'
                              onClick={() => openEditModal(data)}
                              aria-label={`Update trajet ${data?.bus?.name}`}
                            >
                              Update
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </Skeleton>
        )}
      </div>

      {/* Trajet list — pagination */}
      {response?.data?.total_page > 1 && (
        <Pagination
          className='my-5'
          onChange={(e) => setQuery((prevState) => ({ ...prevState, page: e }))}
          total={response?.data?.total_page * 10}
          current={response?.data?.current_page}
        />
      )}

      {/* Edit trajet modal component */}
      {editTrajetModal.open && (
        <EditTrajetModal
          editTrajetModal={editTrajetModal.open}
          setEditTrajetModal={closeEditModal}
          trajet={editTrajetModal.trajet}
        />
      )}
    </div>
  );
}

export default React.memo(TrajetList);
