import React, { useEffect, useState } from 'react';
import {
  Empty, Result, Skeleton, Tag
} from 'antd';
import { v4 as uuid } from 'uuid';
import useFetchData from '../../hooks/useFetchData';
import QueryOptions from '../shared/QueryOptions';
import { getRoleResponse } from '../../utils/responseAsStatus';

function WalletList({ add }) {
  const [query, setQuery] = useState({
    search: '',
    sort: 'desc',
    page: 1,
    rows: 10
  });

  // Fetch wallet list data
  const [loading, error, response] = useFetchData(
    `/all-user-wallet-list?keyword=${query.search}&limit=${query.rows}&page=${query.page}&sort=${query.sort}`
  );

  // Reset query options
  useEffect(() => {
    setQuery((prevState) => ({ ...prevState, page: 1 }));
  }, [query.rows, query.search]);

  // Filter out users with DRIVER_ROLE and ADMIN_ROLE
  const filteredUsers = response?.data?.rows?.filter((data) => !['DRIVER_ROLE', 'ADMIN_ROLE'].includes(data.role)) || [];

  return (
    <div>
      <QueryOptions query={query} setQuery={setQuery} />

      <div className='w-full flex flex-col items-center'>
        {error ? (
          <Result title='Failed to fetch' subTitle={error} status='error' />
        ) : (
          <Skeleton loading={loading} paragraph={{ rows: 10 }} active>
            {filteredUsers.length === 0 ? (
              <Empty className='mt-10' description={<span>Sorry! No data found.</span>} />
            ) : (
              <div className='table-layout'>
                <br />
                <br />
                <div className='table-layout-container'>
                  <table className='data-table'>
                    <thead className='data-table-head'>
                      <tr className='data-table-head-tr'>
                        <th className='data-table-head-tr-th'>
                          Utilisateur
                          {filteredUsers.length > 1 && 's'}
                          {' '}
                          (
                          {filteredUsers.length}
                          )
                        </th>
                        <th className='data-table-head-tr-th'>Role</th>
                        <th className='data-table-head-tr-th text-center'>
                          Solde
                          {' '}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredUsers.map((data) => (
                        <tr
                          className='data-table-body-tr'
                          key={uuid()}
                          onClick={() => add(data.id)}
                        >
                          <td className='data-table-body-tr-td cursor-pointer'>
                            <span className='font-semibold'>
                              {`${data.name} ${data.firstname} `}
                            </span>
                            <span className='text-gray-400'>
                              (
                              {data.email}
                              )
                            </span>
                          </td>
                          <td className='data-table-body-tr-td text-center'>
                            <Tag
                              className='w-[70px] text-center uppercase'
                              color={getRoleResponse(data?.role).color}
                            >
                              {getRoleResponse(data?.role).label}
                            </Tag>
                          </td>
                          <td className='data-table-body-tr-td text-center'>
                            {`${data.balance} Ar`}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </Skeleton>
        )}
      </div>
    </div>
  );
}

export default WalletList;
