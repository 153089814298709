/**
 * @name Trajet Management System
 * @author Mr. Josia Yvan
 * @description Trajet Management System Software ~ Developed By Mr. Josia Yvan
 * @copyright ©2024 ― Mr. Josia Yvan. All rights reserved.
 * @version v0.0.1
 *
 */

import { AntCloudOutlined, AppstoreAddOutlined } from '@ant-design/icons';
import { Button, Tabs } from 'antd';
import React, { useRef, useState } from 'react';
import CreateTrajet from '../trajet/CreateTrajet';
import TrajetDetails from '../trajet/TrajetDetails';
import TrajetList from '../trajet/TrajetList';
import CreateSpot from '../trajet/CreateSpot';

function Trajet() {
  const add = (trajet) => {
    const newActiveKey = `NewTab1${newTabIndex.current++}`;
    setItems([
      ...items,
      {
        key: newActiveKey,
        label: 'Détails du Trajet',
        children: <TrajetDetails trajet={trajet} />
      }
    ]);
    setActiveKey(newActiveKey);
  };

  const add2 = () => {
    const newActiveKey = `NewTab2${newTabIndex.current++}`;
    setItems([
      ...items,
      {
        key: newActiveKey,
        label: 'Créer un Trajet',
        children: <CreateTrajet />
      }
    ]);
    setActiveKey(newActiveKey);
  };

  const add3 = () => {
    const newActiveKey = `NewTab3${newTabIndex.current++}`;
    setItems([
      ...items,
      {
        key: newActiveKey,
        label: 'Créer un SPOT',
        children: <CreateSpot />
      }
    ]);
    setActiveKey(newActiveKey);
  };

  const defaultPanes = new Array(1).fill(null).map((_, index) => ({
    key: String(index + 1),
    label: 'Les Trajet',
    children: <TrajetList add={add} />,
    closable: false
  }));

  const [activeKey, setActiveKey] = useState(defaultPanes[0].key);
  const [items, setItems] = useState(defaultPanes);
  const newTabIndex = useRef(0);

  const remove = (targetKey) => {
    const targetIndex = items.findIndex((pane) => pane.key === targetKey);
    const newPanes = items.filter((pane) => pane.key !== targetKey);
    if (newPanes.length && targetKey === activeKey) {
      const { key } = newPanes[targetIndex === newPanes.length ? targetIndex - 1 : targetIndex];
      setActiveKey(key);
    }
    setItems(newPanes);
  };

  const onEdit = (targetKey, action) => {
    if (action === 'add') {
      add2();
    } else {
      remove(targetKey);
    }
  };

  return (
    <Tabs
      onChange={(key) => setActiveKey(key)}
      tabBarExtraContent={(
        <>
          <Button
            className='inline-flex items-center'
            icon={<AntCloudOutlined />}
            onClick={add3}
            type='dashed'
            size='large'
          >
            Point de spot
          </Button>
          <Button
            className='inline-flex items-center'
            icon={<AppstoreAddOutlined />}
            onClick={add2}
            type='primary'
            size='large'
          >
            Proposer un nouveau Trajet
          </Button>
        </>
      )}
      activeKey={activeKey}
      type='editable-card'
      onEdit={onEdit}
      items={items}
      size='large'
      hideAdd
    />
  );
}

export default React.memo(Trajet);
