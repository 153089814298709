/**
 * @name Subscription Management System
 * @author Mr. Josia Yvan
 * @description Subscription Management System ~ Developed By Mr. Josia Yvan
 * @copyright ©2024 ― Mr. Josia Yvan. All rights reserved.
 * @version v0.0.1
 *
 */

import { Tabs } from 'antd';
import React, { useRef, useState } from 'react';
import SubscriptionList from '../subscription/SubscriptionList'; // Liste des abonnements
import SubscriptionDetails from '../subscription/SubscriptionDetails'; // Détails d'un abonnement

function Subscription() {
  // Fonction pour ajouter un nouvel onglet pour les détails d'un abonnement
  const add = (id) => {
    const newActiveKey = `NewTab1${newTabIndex.current++}`;
    setItems([
      ...items,
      {
        key: newActiveKey,
        label: 'Détail de l`abonnement',
        children: <SubscriptionDetails id={id} />
      }
    ]);
    setActiveKey(newActiveKey);
  };

  // Onglet par défaut avec la liste des abonnements
  const defaultPanes = new Array(1).fill(null).map((_, index) => ({
    key: String(index + 1),
    label: 'Les abonnements',
    children: <SubscriptionList add={add} />,
    closable: false
  }));

  const [activeKey, setActiveKey] = useState(defaultPanes[0].key);
  const [items, setItems] = useState(defaultPanes);
  const newTabIndex = useRef(0);

  // Fonction pour supprimer un onglet
  const remove = (targetKey) => {
    const targetIndex = items.findIndex((pane) => pane.key === targetKey);
    const newPanes = items.filter((pane) => pane.key !== targetKey);
    if (newPanes.length && targetKey === activeKey) {
      const { key } = newPanes[targetIndex === newPanes.length ? targetIndex - 1 : targetIndex];
      setActiveKey(key);
    }
    setItems(newPanes);
  };

  // Fonction pour éditer (ajouter ou supprimer des onglets)
  const onEdit = (targetKey, action) => {
    if (action === 'add') {
      add();
    } else {
      remove(targetKey);
    }
  };

  return (
    <Tabs
      onChange={(key) => setActiveKey(key)}
      // tabBarExtraContent={(
      //   <Button
      //     className='inline-flex items-center'
      //     icon={<AppstoreAddOutlined />}
      //     onClick={add2}
      //     type='primary'
      //     size='large'
      //   >
      //     Proposer un nouvel abonnement
      //   </Button>
      // )}
      activeKey={activeKey}
      type='editable-card'
      onEdit={onEdit}
      items={items}
      size='large'
      hideAdd
    />
  );
}

export default React.memo(Subscription);
