/* eslint-disable no-underscore-dangle */
/* eslint-disable no-shadow */
import React from 'react';
import { Button, Descriptions, Modal } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import notificationWithIcon from '../../utils/notification';
import ApiService from '../../utils/apiService';
import { reFetchData } from '../../store/slice/appSlice';
import { formatDateInFrench, formatMinutesToHoursAndMinutes } from '../../utils/format';

const { confirm } = Modal;

function TrajetDetails({ trajet }) {
  const dispatch = useDispatch();

  const handleDeleteTrajet = (id) => {
    confirm({
      title: 'DELETE Trajet',
      icon: <ExclamationCircleFilled />,
      content: 'Are you sure you want to delete this trajet permanently?',
      onOk() {
        return new Promise((resolve, reject) => {
          ApiService.delete(`/delete-trajet/${id}`)
            .then((res) => {
              if (res?.result_code === 0) {
                notificationWithIcon('success', 'SUCCESS', res?.result?.message || 'Trajet deleted successfully');
                dispatch(reFetchData());
                resolve();
              } else {
                notificationWithIcon('error', 'ERROR', 'Sorry! Something went wrong. App server error');
                reject();
              }
            })
            .catch((err) => {
              notificationWithIcon('error', 'ERROR', err?.trajet.result?.error?.message || err?.trajet.result?.error || 'Sorry! Something went wrong. App server error');
              reject();
            });
        }).catch(() => notificationWithIcon('error', 'ERROR', 'Oops errors!'));
      }
    });
  };

  console.log(trajet, 'propos');

  // todo: afficher le map
  //* les chaises du bus

  return (

    <Descriptions
      title='Trajet Information'
      bordered
      extra={(
        <Button onClick={() => handleDeleteTrajet(trajet.id)} type='default' danger>
          Delete Trajet
        </Button>
          )}
    >
      <Descriptions.Item className='font-bold' label='Bus' span={2}>
        {' '}
        <span className='font-bold'>{trajet.bus.name}</span>
        {' '}
        [
        {trajet.bus.licensePlate}
        ]
      </Descriptions.Item>
      <Descriptions.Item className='font-bold' label='Départ' span={2}>{trajet.departure.name}</Descriptions.Item>
      <Descriptions.Item className='font-bold' label='Arrivée' span={2}>{trajet.arrival.name}</Descriptions.Item>
      <Descriptions.Item className='font-bold' label='Temps estimé' span={2}>
        {trajet.estimationTime && formatMinutesToHoursAndMinutes(trajet.estimationTime)}
      </Descriptions.Item>
      <Descriptions.Item className='font-bold' label='Date de départ' span={2}>
        {formatDateInFrench(trajet.date)}
      </Descriptions.Item>
      <Descriptions.Item className='font-bold' label='Heure de départ' span={2}>
        {trajet.departureTime}
      </Descriptions.Item>
      <Descriptions.Item className='font-bold' label='Distance' span={2}>
        {trajet.distance}
        {' '}
        km
      </Descriptions.Item>
    </Descriptions>
  );
}

export default React.memo(TrajetDetails);
