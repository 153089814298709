/**
 * @name Busnay
 * @author Mr. Josia Yvan
 * @description System API and Management System Software ~ Developed By Mr. Josia Yvan
 * @copyright ©2024 ― Mr. Josia Yvan. All rights reserved.
 * @version v0.0.1
 *
 */
import {
  Button, Empty, Pagination, Result, Skeleton, Tag
} from 'antd';
import React, { useEffect, useState } from 'react';
import { v4 as uniqueId } from 'uuid';
import useFetchData from '../../hooks/useFetchData';
import QueryOptions from '../shared/QueryOptions';
import UserStatusUpdateModal from '../shared/UserStatusUpdateModal';
import { getRoleResponse, getSexeResponse } from '../../utils/responseAsStatus';

function UsersList({ add }) {
  const [fetchAgain, setFetchAgain] = useState(false);
  const [query, setQuery] = useState({
    search: '', sort: 'asce', page: '1', rows: '10'
  });
  const [statusUpdateModal, setStatusUpdateModal] = useState(
    { open: false, id: null, status: null }
  );

  // fetch user-list API data
  const [loading, error, response] = useFetchData(`/all-users-list?keyword=${query.search}&limit=${query.rows}&page=${query.page}&sort=${query.sort}`, fetchAgain);

  // reset query options
  useEffect(() => {
    setQuery((prevState) => ({ ...prevState, page: '1' }));
  }, [query.rows, query.search]);

  return (
    <div>
      {/* user list ― query section */}
      <QueryOptions query={query} setQuery={setQuery} />

      {/* user list ― content section */}
      <div className='w-full flex flex-row flex-wrap items-center justify-center gap-2'>
        {error ? (
          <Result
            title='Failed to fetch'
            subTitle={error}
            status='error'
          />
        ) : (
          <Skeleton loading={loading} paragraph={{ rows: 10 }} active>
            {response?.data?.rows?.length === 0 ? (
              <Empty
                className='mt-10'
                description={(<span>Sorry! Any data was not found.</span>)}
              />
            ) : (
              <div className='table-layout'>
                <div className='table-layout-container'>
                  <table className='data-table'>
                    {/* data table ― head */}
                    <thead className='data-table-head'>
                      <tr className='data-table-head-tr'>
                        <th className='data-table-head-tr-th' scope='col'>
                          Nom
                        </th>
                        <th className='data-table-head-tr-th' scope='col'>
                          Prénom
                        </th>
                        <th className='data-table-head-tr-th' scope='col'>
                          Email
                        </th>
                        <th className='data-table-head-tr-th' scope='col'>
                          Adresse
                        </th>
                        <th className='data-table-head-tr-th' scope='col'>
                          Téléphone
                        </th>
                        <th className='data-table-head-tr-th' scope='col'>
                          Occupation
                        </th>
                        <th className='data-table-head-tr-th' scope='col'>
                          Role
                        </th>
                        <th className='data-table-head-tr-th' scope='col'>
                          Sexe
                        </th>
                        <th className='data-table-head-tr-th text-center' scope='col'>
                          Actions
                        </th>
                      </tr>
                    </thead>

                    {/* data table ― body */}
                    <tbody>
                      {response?.data?.rows?.map((data) => (
                        <tr className='data-table-body-tr' key={uniqueId()}>
                          <td className='data-table-body-tr-td text-center'>
                            {data?.name}
                          </td>
                          <td className='data-table-body-tr-td'>
                            {data?.firstname}
                          </td>
                          <td className='data-table-body-tr-td'>
                            {data?.email}
                          </td>
                          <td className='data-table-body-tr-td'>
                            {data?.district}
                          </td>
                          <td className='data-table-body-tr-td'>
                            {data?.phone}
                          </td>
                          <td className='data-table-body-tr-td'>
                            {data?.occupation}
                          </td>
                          <td className='data-table-body-tr-td text-center'>
                            <Tag
                              className='w-[70px] text-center uppercase'
                              color={getRoleResponse(data?.role).color}
                            >
                              {getRoleResponse(data?.role).label}
                            </Tag>
                          </td>
                          <td className='data-table-body-tr-td text-center'>
                            <Tag
                              className='w-[70px] text-center uppercase'
                              color={getSexeResponse(data?.sexe).color}
                            >
                              {getSexeResponse(data?.sexe).label}
                            </Tag>
                          </td>
                          <td className='data-table-body-tr-td !px-0 text-center'>
                            <Button
                              className='inline-flex items-center !px-2'
                              onClick={() => add(data?.id)}
                              type='link'
                            >
                              View
                            </Button>
                            {/* <Button
                              className='inline-flex items-center !px-2'
                              type='primary'
                              onClick={() => setStatusUpdateModal((prevState) => ({
                                ...prevState, open: true, id: data?.id, status: data?.isSubscribed
                              }))}
                            >
                              Update Status
                            </Button> */}

                            {/* {user?.id !== data?.id && (
                              <Button
                                className='inline-flex items-center !px-2'
                                onClick={() => handleDeleteUser(data?.id)}
                                type='link'
                              >
                                Delete
                              </Button>
                            )} */}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </Skeleton>
        )}
      </div>

      {/* user list ― pagination */}
      {response?.data?.total_page > 1 && (
        <Pagination
          className='my-5'
          onChange={(e) => setQuery((prevState) => ({ ...prevState, page: e }))}
          total={response?.data?.total_page * 10}
          current={response?.data?.current_page}
        />
      )}

      {/* room status update modal component */}
      {statusUpdateModal?.open && (
        <UserStatusUpdateModal
          statusUpdateModal={statusUpdateModal}
          setStatusUpdateModal={setStatusUpdateModal}
          setFetchAgain={setFetchAgain}
        />
      )}
    </div>
  );
}

export default React.memo(UsersList);
