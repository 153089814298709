/* eslint-disable no-underscore-dangle */
import {
  Button, Card, Form, Input, Select, Alert, Modal, DatePicker
} from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { reFetchData } from '../../store/slice/appSlice';
import ApiService from '../../utils/apiService';
import notificationWithIcon from '../../utils/notification';
import useFetchData from '../../hooks/useFetchData';

const { Option } = Select;

function TrajetEditModal({ editTrajetModal, setEditTrajetModal, trajet }) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [fetchBusLoading, fetchBusError, fetchBusResponse] = useFetchData('/all-buses-list');
  const [fetchSpotsLoading, fetchSpotsError, fetchSpotsResponse] = useFetchData('/all-spots-list');

  const [spots, setSpots] = useState([]);

  useEffect(() => {
    if (fetchSpotsResponse?.data?.rows) {
      const initialSpots = fetchSpotsResponse.data.rows.map((spot) => ({
        value: spot._id,
        label: spot.name,
        disabled: false
      }));
      setSpots(initialSpots);
    }
  }, [fetchSpotsResponse]);

  useEffect(() => {
    if (trajet) {
      form.setFieldsValue({
        bus: trajet.bus.id,
        departure: trajet.departure.id,
        arrival: trajet.arrival.id,
        departureTime: trajet.departureTime,
        estimationTime: trajet.estimationTime,
        distance: trajet.distance,
        date: trajet.date ? moment(trajet.date) : ''
      });
    }
  }, [trajet, form]);

  const onFinish = (values) => {
    setLoading(true);
    ApiService.put(`/update-trajet/${trajetId}`, values)
      .then((response) => {
        setLoading(false);
        if (response?.result_code === 0) {
          notificationWithIcon('success', 'Succès', response?.result?.message || 'Trajet mis à jour avec succès');
          form.resetFields();
          dispatch(reFetchData());
          setEditTrajetModal(false);
        } else {
          notificationWithIcon('error', 'Erreur', 'Désolé ! Quelque chose s`est mal passé. Erreur serveur');
        }
      })
      .catch((err) => {
        setLoading(false);
        notificationWithIcon('error', 'Erreur', err?.response?.data?.result?.error?.message || 'Désolé ! Quelque chose s`est mal passé. Erreur serveur');
      });
  };

  const handleSpotChange = (value, field) => {
    const updatedSpots = spots.map((spot) => ({ ...spot, disabled: false }));
    const selectedSpot = updatedSpots.find((spot) => spot.value === value);
    if (selectedSpot) {
      selectedSpot.disabled = true;
    }
    setSpots(updatedSpots);
    form.setFieldsValue({ [field]: value });
  };

  return (
    <Modal
      title='Mettre à jour le trajet'
      open={editTrajetModal}
      onCancel={() => setEditTrajetModal(false)}
      footer={null}
      width={800}
    >
      <Card>
        {(fetchBusError || fetchSpotsError) && (
        <Alert message='Erreur de chargement des données' type='error' />
        )}
        <Form
          form={form}
          name='edit-trajet-form'
          onFinish={onFinish}
          layout='vertical'
        >
          <div className='two-grid-column'>
            <Form.Item
              label='Bus'
              className='w-full md:w-1/2'
              name='bus'
              rules={[{ required: true, message: 'Veuillez sélectionner un bus !' }]}
            >
              <Select
                placeholder='-- Sélectionner un bus --'
                size='large'
                allowClear
                loading={fetchBusLoading}
              >
                {fetchBusResponse?.data?.rows?.map((bus) => (
                  <Option key={bus.id} value={bus.id}>
                    <span className='font-bold'>{bus.name}</span>
                    {' '}
                    [
                    {bus.licensePlate}
                    ]
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label='Date'
              className='w-full md:w-1/2'
              name='date'
              rules={[{ required: true, message: 'Veuillez sélectionner une date !' }]}
            >
              <DatePicker
                placeholder='-- Sélectionner une date --'
                size='large'
                style={{ width: '100%' }}
                format='YYYY-MM-DD'
              />
            </Form.Item>
          </div>

          <div className='two-grid-column'>
            <Form.Item
              label='Point de départ'
              className='w-full md:w-1/2'
              name='departure'
              rules={[{ required: true, message: 'Veuillez sélectionner un point de départ !' }]}
            >
              <Select
                placeholder='-- Sélectionner un point de départ --'
                size='large'
                allowClear
                loading={fetchSpotsLoading}
                onChange={(value) => handleSpotChange(value, 'departure')}
              >
                {spots.map((spot) => (
                  <Option key={spot.value} value={spot.value} disabled={spot.disabled}>
                    {spot.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label='Heure de départ'
              className='w-full md:w-1/2'
              name='departureTime'
              rules={[{ required: true, message: 'Veuillez saisir l`heure de départ !' }]}
            >
              <Input placeholder='Heure de départ' size='large' type='time' allowClear />
            </Form.Item>
          </div>

          <div className='two-grid-column'>
            <Form.Item
              label='Point d`arrivée'
              className='w-full md:w-1/2'
              name='arrival'
              rules={[{ required: true, message: 'Veuillez sélectionner un point d`arrivée !' }]}
            >
              <Select
                placeholder='-- Sélectionner un point d`arrivée --'
                size='large'
                allowClear
                loading={fetchSpotsLoading}
                onChange={(value) => handleSpotChange(value, 'arrival')}
              >
                {spots.map((spot) => (
                  <Option key={spot.value} value={spot.value} disabled={spot.disabled}>
                    {spot.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label='Temps estimé (en minutes)'
              className='w-full md:w-1/2'
              name='estimationTime'
              rules={[{ required: true, message: 'Veuillez saisir le temps estimé du trajet !' }]}
            >
              <Input placeholder='Temps estimé' size='large' type='number' allowClear />
            </Form.Item>
          </div>

          <div className='two-grid-column'>
            <Form.Item
              label='Distance (en km)'
              className='w-full md:w-1/2'
              name='distance'
              rules={[{ required: true, message: 'Veuillez saisir la distance du trajet !' }]}
            >
              <Input placeholder='Distance' size='large' type='number' allowClear />
            </Form.Item>

            <Form.Item className='float-right'>
              <Button
                htmlType='submit'
                type='primary'
                size='large'
                loading={loading}
              >
                Mettre à jour
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Card>
    </Modal>
  );
}

export default TrajetEditModal;
