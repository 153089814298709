/* eslint-disable import/no-extraneous-dependencies */
/**
 * @name Offer Management System
 * @description Manage and Edit Offers
 * @version v0.0.1
 */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import 'moment/locale/fr'; // Importer la locale souhaitée, par exemple, le français

import { TagOutlined, DollarOutlined } from '@ant-design/icons';
import {
  Button, Form, Input, Modal, Result,
  Select
} from 'antd';

import useFetchData from '../../hooks/useFetchData';
import { reFetchData } from '../../store/slice/appSlice';
import ApiService from '../../utils/apiService';
import notificationWithIcon from '../../utils/notification';
import PageLoader from '../shared/PageLoader';

// Définir la locale globalement
moment.locale('fr'); // ou la locale de votre choix

function OfferEditModal({ editOfferModal, setEditOfferModal, offerId }) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [isDisponible, setIsDisponible] = useState([
    { value: 'disponible', label: 'Disponible', disabled: false },
    { value: 'indisponible', label: 'Indisponible', disabled: false }
  ]);

  // fetch des données d'offre API
  const [fetchLoading, fetchError, fetchResponse] = useFetchData(`/get-offer/${offerId}`);

  // définir les données du formulaire à partir des données API
  useEffect(() => {
    if (fetchResponse) {
      form.setFieldsValue({
        title: fetchResponse?.data?.title || undefined,
        description: fetchResponse?.data?.description || undefined,
        price: fetchResponse?.data?.price || undefined,
        tag: fetchResponse?.data?.tag || undefined,
        duration: fetchResponse?.data?.duration || undefined,
        access: fetchResponse?.data?.access || undefined,
        status: fetchResponse?.data?.status
      });
    }

    if (fetchResponse?.data?.status === 'disponible') {
      setIsDisponible([
        { value: 'disponible', label: 'Disponible', disabled: true },
        { value: 'indisponible', label: 'Indisponible', disabled: false }
      ]);
    } else if (fetchResponse?.data?.status === 'indisponible') {
      setIsDisponible([
        { value: 'disponible', label: 'Disponible', disabled: false },
        { value: 'indisponible', label: 'Indisponible', disabled: true }
      ]);
    }
  }, [fetchResponse, form]);

  // fonction pour gérer l'édition de l'offre
  const onFinish = (values) => {
    setLoading(true);
    ApiService.put(`/update-offer/${offerId}`, values)
      .then((response) => {
        setLoading(false);
        if (response?.result_code === 0) {
          notificationWithIcon('success', 'Succès', response?.result?.message || 'Offre mise à jour avec succès');
          form.resetFields();
          dispatch(reFetchData());
          setEditOfferModal(false);
        } else {
          notificationWithIcon('error', 'Erreur', 'Désolé ! Quelque chose s`est mal passé. Erreur serveur');
        }
      })
      .catch((err) => {
        setLoading(false);
        notificationWithIcon('error', 'Erreur', err?.response?.data?.result?.error?.message || 'Désolé ! Quelque chose s`est mal passé. Erreur serveur');
      });
  };

  return (
    <Modal
      title='Mettre à jour l`offre'
      open={editOfferModal}
      onOk={() => setEditOfferModal(false)}
      onCancel={() => setEditOfferModal(false)}
      footer={null}
      width={800}
    >
      {fetchLoading ? (<PageLoader />) : fetchError ? (
        <Result
          title='Échec de la récupération'
          subTitle={fetchError}
          status='error'
        />
      ) : (
        <Form
          form={form}
          className='offer-form'
          name='edit-offer-form'
          onFinish={onFinish}
          layout='vertical'
        >
          <Form.Item
            label='Titre'
            name='title'
            rules={[{ required: true, message: 'Veuillez entrer le titre de l`offre !' }]}
          >
            <Input
              prefix={<TagOutlined />}
              placeholder='Titre de l`offre'
              size='large'
              allowClear
            />
          </Form.Item>

          <Form.Item
            label='Description'
            name='description'
            rules={[{ required: true, message: 'Veuillez entrer la description de l`offre !' }]}
          >
            <Input.TextArea
              placeholder='Description de l`offre'
              rows={4}
              size='large'
              allowClear
            />
          </Form.Item>

          <Form.Item
            label='Prix'
            name='price'
            rules={[{ required: true, message: 'Veuillez entrer le prix de l`offre !' }]}
          >
            <Input
              prefix={<DollarOutlined />}
              placeholder='Prix de l`offre'
              size='large'
              type='number'
              allowClear
            />
          </Form.Item>

          <Form.Item
            label='Étiquette'
            name='tag'
            rules={[{ required: true, message: 'Veuillez entrer l`étiquette de l`offre !' }]}
          >
            <Input
              prefix={<TagOutlined />}
              placeholder='Étiquette de l`offre'
              size='large'
              allowClear
            />
          </Form.Item>

          <Form.Item
            label='Durée (jours)'
            name='duration'
            rules={[{ required: true, message: 'Veuillez entrer la durée de l`offre !' }]}
          >
            <Input
              placeholder='Durée en jours'
              size='large'
              type='number'
              allowClear
            />
          </Form.Item>

          <Form.Item
            label='Limite d`accès'
            name='access'
            rules={[{ required: true, message: 'Veuillez entrer la limite d`accès de l`offre !' }]}
          >
            <Input
              placeholder='Limite d`accès par jour'
              size='large'
              type='number'
              allowClear
            />
          </Form.Item>
          <Form.Item
            label='Statut'
            name='status'
            rules={[{ required: true, message: 'Veuillez sélectionner le statut de l`offre !' }]}
          >
            <Select
              className='w-full my-5'
              placeholder='-- sélectionner le statut --'
              optionFilterProp='children'
              options={isDisponible}
              size='large'
              allowClear
            />
          </Form.Item>

          <Form.Item>
            <Button
              type='primary'
              htmlType='submit'
              size='large'
              loading={loading}
              disabled={loading}
            >
              Mettre à jour
            </Button>
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
}

export default React.memo(OfferEditModal);
