import React, { useEffect, useState } from 'react';
import { v4 as uniqueId } from 'uuid';
import {
  Button, Empty, Pagination, Result, Skeleton, Tag
} from 'antd';
import useFetchData from '../../hooks/useFetchData';
import QueryOptions from '../shared/QueryOptions';
import EditOfferModal from './EditOfferModal';

function OfferList({ add }) {
  const [query, setQuery] = useState({
    search: '', sort: 'asce', page: '1', rows: '10'
  });
  const [fetchAgain, setFetchAgain] = useState(false);
  const [editOfferModal, setEditOfferModal] = useState({ open: false, id: null });

  // fetch Offer-list API data
  const [loading, error, response] = useFetchData(`/all-offers-list?keyword=${query.search}&limit=${query.rows}&page=${query.page}&sort=${query.sort}`, fetchAgain);

  // reset query options
  useEffect(() => {
    setQuery((prevState) => ({ ...prevState, page: '1' }));
  }, [query.rows, query.search]);

  // Open edit offer modal
  const openEditModal = (id) => {
    setEditOfferModal({ open: true, id });
  };

  // Close edit offer modal
  const closeEditModal = () => {
    setEditOfferModal({ open: false, id: null });
    setFetchAgain(!fetchAgain); // Refresh the list after editing
  };

  return (
    <div>
      {/* Offer list ― query section */}
      <QueryOptions query={query} setQuery={setQuery} />

      {/* Offer list ― content section */}
      <div className='w-full flex flex-row flex-wrap items-center justify-center gap-2'>
        {error ? (
          <Result
            title='Failed to fetch'
            subTitle={error}
            status='error'
          />
        ) : (
          <Skeleton loading={loading} paragraph={{ rows: 10 }} active>
            {response?.data?.rows?.length === 0 ? (
              <Empty
                className='mt-10'
                description={<span>Sorry! No data found.</span>}
              />
            ) : (
              <div className='table-layout'>
                <div className='table-layout-container'>
                  <table className='data-table'>
                    {/* data table ― head */}
                    <thead className='data-table-head'>
                      <tr className='data-table-head-tr'>
                        <th className='data-table-head-tr-th text-center' scope='col'>
                          libélé
                        </th>
                        <th className='data-table-head-tr-th text-center' scope='col'>
                          description
                        </th>
                        <th className='data-table-head-tr-th text-center' scope='col'>
                          statut
                        </th>
                        <th className='data-table-head-tr-th text-center' scope='col'>
                          Étiquette
                        </th>
                        <th className='data-table-head-tr-th text-center' scope='col'>
                          prix
                        </th>
                        <th className='data-table-head-tr-th text-center' scope='col'>
                          durée
                        </th>
                        <th className='data-table-head-tr-th text-center' scope='col'>
                          accès
                        </th>
                        <th className='data-table-head-tr-th text-center' scope='col'>
                          Actions
                        </th>
                      </tr>
                    </thead>

                    {/* data table ― body */}
                    <tbody>
                      {response?.data?.rows?.map((data) => (
                        <tr className='data-table-body-tr' key={uniqueId()}>
                          <td className='data-table-body-tr-td text-center'>
                            {data?.title}
                          </td>
                          <td className='data-table-body-tr-td text-center'>
                            {data?.description}
                          </td>
                          <td className='data-table-body-tr-td text-center'>
                            <Tag className='text-center uppercase' color={data?.status === 'disponible' ? 'lime-inverse' : 'default'}>{data?.status}</Tag>
                          </td>
                          <td className='data-table-body-tr-td text-center'>
                            <Tag className='text-center uppercase' color='volcano-inverse'>{data?.tag}</Tag>
                          </td>
                          <td className='data-table-body-tr-td text-center'>
                            {data?.price}
                            {' '}
                            Ariary
                          </td>
                          <td className='data-table-body-tr-td text-center'>
                            {data?.duration}
                            {' '}
                            Jour
                            {response?.data?.duration > 1 && 's'}
                          </td>
                          <td className='data-table-body-tr-td text-center'>
                            {data?.access}
                            {' '}
                            /jour
                          </td>
                          <td className='data-table-body-tr-td text-center'>
                            <Button
                              className='inline-flex items-center !px-2'
                              onClick={() => add(data?.id)}
                              type='link'
                            >
                              View
                            </Button>
                            <Button
                              className='inline-flex items-center !px-2'
                              type='primary'
                              onClick={() => openEditModal(data?.id)}
                            >
                              Update
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </Skeleton>
        )}
      </div>

      {/* Offer list ― pagination */}
      {response?.data?.total_page > 1 && (
        <Pagination
          className='my-5'
          onChange={(e) => setQuery((prevState) => ({ ...prevState, page: e }))}
          total={response?.data?.total_page * 10}
          current={response?.data?.current_page}
        />
      )}

      {/* Edit offer modal component */}
      {editOfferModal.open && (
        <EditOfferModal
          editOfferModal={editOfferModal.open}
          setEditOfferModal={closeEditModal}
          offerId={editOfferModal.id}
        />
      )}
    </div>
  );
}

export default React.memo(OfferList);
