/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { Card, Tooltip } from 'antd';
import './App.css';

const SeatStatus = {
  FREE: 'free',
  OCCUPIED: 'occupied',
  SPECIAL: 'special'
};

function Seat({ status = SeatStatus.FREE }) {
  const getSeatImage = () => {
    if (status === SeatStatus.OCCUPIED) return '/vectors/free_seat.svg';
    return status === SeatStatus.SPECIAL ? '/vectors/volant_bus.svg' : '/vectors/free_seat.svg';
  };

  return (
    <Tooltip title={status === SeatStatus.SPECIAL ? 'Steering Wheel' : 'Free seat'}>
      <div className='seat' role='button' aria-label='Seat'>
        <img src={getSeatImage()} alt='seat' className='seat-image' />
      </div>
    </Tooltip>
  );
}

function EmptySeat() {
  return <div className='empty-seat' />;
}

function Aisle() {
  return <div className='aisle' />;
}

function SeatView({ seatsLayout, aislePosition }) {
  const seats = [];

  seatsLayout.forEach((seat) => {
    const { row, column, status } = seat;
    if (!seats[row]) {
      seats[row] = Array(aislePosition * 2).fill(null);
    }
    seats[row][column] = status;
  });

  return (
    <div className='bus-layout'>
      <Card className='seats'>
        {seats.map((row, rowIndex) => (
          <div className='seat-row' key={rowIndex}>
            {row.slice(0, aislePosition).map((seatStatus, colIndex) => (
              seatStatus ? <Seat key={colIndex} status={seatStatus} /> : <EmptySeat key={colIndex} />
            ))}
            <Aisle />
            {row.slice(aislePosition).map((seatStatus, colIndex) => (
              seatStatus ? <Seat key={colIndex + aislePosition} status={seatStatus} /> : <EmptySeat key={colIndex + aislePosition} />
            ))}
          </div>
        ))}
      </Card>
    </div>
  );
}

export default SeatView;
