/* eslint-disable import/no-extraneous-dependencies */
/**
 * @name Bus Management System
 * @description Manage and Edit Bus Details
 * @version v0.0.1
 */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import 'moment/locale/fr'; // Importer la locale souhaitée, par exemple, le français

import { TagOutlined } from '@ant-design/icons';
import {
  Button, Form, Input, Modal, Result,
  Select
} from 'antd';

import { Option } from 'antd/es/mentions';
import useFetchData from '../../hooks/useFetchData';
import { reFetchData } from '../../store/slice/appSlice';
import ApiService from '../../utils/apiService';
import notificationWithIcon from '../../utils/notification';
import PageLoader from '../shared/PageLoader';
import BusSeatLayout from './BusSeatLayout';

moment.locale('fr');

function BusEditModal({ editBusModal, setEditBusModal, busId }) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedSeats, setSelectedSeats] = useState({});
  const [form] = Form.useForm();

  const tagOptions = [
    { value: 'on_way', label: 'En route', disabled: false },
    { value: 'pickup', label: 'Ramassage', disabled: false },
    { value: 'drops_off', label: 'Dépôt', disabled: false },
    { value: 'parking', label: 'Parking', disabled: true }
  ];

  const [isDisponible, setIsDisponible] = useState(tagOptions);

  // fetch des données de bus API
  const [fetchLoading, fetchError, fetchResponse] = useFetchData(`/bus/${busId}`);
  const [fetchDriverLoading, , fetchDriverResponse] = useFetchData('/get-users-by?role=DRIVER_ROLE');
  // définir les données du formulaire à partir des données API
  useEffect(() => {
    if (fetchResponse) {
      form.setFieldsValue({
        name: fetchResponse?.data?.name || undefined,
        driver: fetchResponse?.data?.driver?.id || undefined,
        licensePlate: fetchResponse?.data?.licensePlate || undefined,
        placeNumber: fetchResponse?.data?.placeNumber || undefined,
        brand: fetchResponse?.data?.brand || undefined,
        tag: fetchResponse?.data?.tag || undefined
      });
    }

    const selectedTag = fetchResponse?.data?.tag;
    setIsDisponible(tagOptions.map((tag) => ({
      ...tag,
      disabled: tag.value === selectedTag
    })));
  }, [fetchResponse, form]);

  const showModal = () => setModalVisible(true);
  const handleCancel = () => setModalVisible(false);
  const handleSeatSelection = (seats) => setSelectedSeats(seats);

  const onFinish = (values) => {
    const dataToSubmit = { ...values, seatsLayout: selectedSeats.seats, aislePosition: selectedSeats.aislePosition };

    setLoading(true);
    ApiService.put(`/update-bus/${busId}`, dataToSubmit)
      .then((response) => {
        setLoading(false);
        if (response?.result_code === 0) {
          notificationWithIcon('success', 'Succès', response?.result?.message || 'Bus mis à jour avec succès');
          form.resetFields();
          dispatch(reFetchData());
          setEditBusModal(false);
        } else {
          notificationWithIcon('error', 'Erreur', 'Désolé ! Quelque chose s`est mal passé. Erreur serveur');
        }
      })
      .catch((err) => {
        setLoading(false);
        notificationWithIcon('error', 'Erreur', err?.response?.data?.result?.error?.message || 'Désolé ! Quelque chose s`est mal passé. Erreur serveur');
      });
  };

  return (
    <Modal
      title='Mettre à jour le bus'
      open={editBusModal}
      onOk={() => setEditBusModal(false)}
      onCancel={() => setEditBusModal(false)}
      footer={null}
      width={800}
    >
      {fetchLoading ? (<PageLoader />) : fetchError ? (
        <Result
          title='Échec de la récupération'
          subTitle={fetchError}
          status='error'
        />
      ) : (
        <Form
          form={form}
          className='bus-form'
          name='edit-bus-form'
          onFinish={onFinish}
          layout='vertical'
        >
          <Form.Item
            label='Nom du bus'
            name='name'
            rules={[{ required: true, message: 'Veuillez entrer le nom du bus !' }]}
          >
            <Input
              prefix={<TagOutlined />}
              placeholder='Nom du bus'
              size='large'
              allowClear
            />
          </Form.Item>

          <Form.Item
            label='Conducteur'
            name='driver'
            rules={[{ required: true, message: 'Veuillez entrer le nom du conducteur !' }]}
          >
            <Select
              placeholder='-- Sélectionner un conducteur --'
              size='large'
              allowClear
              loading={fetchDriverLoading}
            >
              {fetchDriverResponse?.data?.rows?.map((driver) => (
                <Option key={driver.id} value={driver.id}>
                  {`${driver.name} ${driver.firstname} - ${driver.address}`}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label='Plaque d&apos;immatriculation'
            name='licensePlate'
            rules={[{ required: true, message: 'Veuillez entrer la plaque d&apos;immatriculation !' }]}
          >
            <Input
              placeholder='Plaque d&apos;immatriculation'
              size='large'
              allowClear
            />
          </Form.Item>

          <Form.Item
            label='Nombre de places'
            name='placeNumber'
            rules={[{ required: true, message: 'Veuillez entrer le nombre de places !' }]}
          >
            <Input
              placeholder='Nombre de places'
              size='large'
              type='number'
              allowClear
            />
          </Form.Item>

          <Form.Item
            label='Marque'
            name='brand'
            rules={[{ required: true, message: 'Veuillez entrer la marque du bus !' }]}
          >
            <Input
              placeholder='Marque du bus'
              size='large'
              allowClear
            />
          </Form.Item>

          <Form.Item
            label='Statut'
            name='tag'
            rules={[{ required: true, message: 'Veuillez sélectionner le statut du bus !' }]}
          >
            <Select
              className='w-full my-5'
              placeholder='-- sélectionner le statut --'
              optionFilterProp='children'
              options={isDisponible}
              size='large'
              allowClear
            />
          </Form.Item>
          <Button type='default' onClick={showModal}>
            Modifier la disposition des sièges
          </Button>

          <Form.Item>
            <Button
              type='primary'
              htmlType='submit'
              size='large'
              loading={loading}
              disabled={loading}
            >
              Mettre à jour
            </Button>
          </Form.Item>
        </Form>
      )}
      <Modal
        title='Disposition des sièges'
        open={modalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <BusSeatLayout seatToExport={handleSeatSelection} />
      </Modal>
    </Modal>
  );
}

export default React.memo(BusEditModal);
