import React, { useEffect, useState } from 'react';
import { v4 as uniqueId } from 'uuid';
import {
  Button, Empty, Pagination, Result, Skeleton, Tag
} from 'antd';
import { Link } from 'react-router-dom';
import useFetchData from '../../hooks/useFetchData';
import QueryOptions from '../shared/QueryOptions';
import EditBusModal from './EditBusModal'; // Modal for bus editing
import { getBusTagAsResponse } from '../../utils/responseAsStatus';

function BusList({ add }) {
  const [query, setQuery] = useState({
    search: '', sort: 'asce', page: '1', rows: '10'
  });
  const [fetchAgain, setFetchAgain] = useState(false);
  const [editBusModal, setEditBusModal] = useState({ open: false, id: null });

  // Fetch Bus-list API data
  const [loading, error, response] = useFetchData(
    `/all-buses-list?keyword=${query.search}&limit=${query.rows}&page=${query.page}&sort=${query.sort}`,
    fetchAgain
  );

  // Reset query options
  useEffect(() => {
    setQuery((prevState) => ({ ...prevState, page: '1' }));
  }, [query.rows, query.search, response]);

  // Open edit bus modal
  const openEditModal = (id) => {
    setEditBusModal({ open: true, id });
  };

  // Close edit bus modal
  const closeEditModal = () => {
    setEditBusModal({ open: false, id: null });
    setFetchAgain(!fetchAgain); // Refresh the list after editing
  };

  return (
    <div>
      {/* Bus list — query section */}
      <QueryOptions query={query} setQuery={setQuery} />

      {/* Bus list — content section */}
      <div className='w-full flex flex-row flex-wrap items-center justify-center gap-2'>
        {error ? (
          <Result
            title='Failed to fetch'
            subTitle={error}
            status='error'
          />
        ) : (
          <Skeleton loading={loading} paragraph={{ rows: 10 }} active>
            {response?.data?.rows?.length === 0 ? (
              <Empty
                className='mt-10'
                description={<span>Sorry! No data found.</span>}
              />
            ) : (
              <div className='table-layout'>
                <div className='table-layout-container'>
                  <table className='data-table'>
                    {/* Data table — head */}
                    <thead className='data-table-head'>
                      <tr className='data-table-head-tr'>
                        <th className='data-table-head-tr-th text-center'>Nom du bus</th>
                        <th className='data-table-head-tr-th text-center'>Conducteur</th>
                        <th className='data-table-head-tr-th text-center'>Plaque d&apos;immatriculation</th>
                        <th className='data-table-head-tr-th text-center'>Nombre de places</th>
                        <th className='data-table-head-tr-th text-center'>Marque</th>
                        <th className='data-table-head-tr-th text-center'>Statut</th>
                        <th className='data-table-head-tr-th text-center'>Actions</th>
                      </tr>
                    </thead>

                    {/* Data table — body */}
                    <tbody>
                      {response?.data?.rows?.map((data) => (
                        <tr className='data-table-body-tr' key={data.id || uniqueId()}>
                          <td className='data-table-body-tr-td text-center'>{data?.name}</td>
                          <td className='data-table-body-tr-td text-center'>
                            <Link to='/main/users'>
                              {data?.driver?.name}
                              {' '}
                              {data?.driver?.firstname}
                            </Link>
                          </td>
                          <td className='data-table-body-tr-td text-center'>
                            <Tag color='grey' className='font-bold uppercase'>
                              {data?.licensePlate}
                            </Tag>
                          </td>
                          <td className='data-table-body-tr-td text-center'>{data?.placeNumber}</td>
                          <td className='data-table-body-tr-td text-center'>{data?.brand}</td>
                          <td className='data-table-body-tr-td text-center'>
                            <Tag className='text-center uppercase' color={getBusTagAsResponse(data?.tag).color}>{getBusTagAsResponse(data?.tag).label}</Tag>
                          </td>
                          <td className='data-table-body-tr-td text-center'>
                            <Button
                              className='inline-flex items-center !px-2'
                              onClick={() => add(data?.id)}
                              type='link'
                              aria-label={`View bus ${data?.name}`}
                            >
                              View
                            </Button>
                            <Button
                              className='inline-flex items-center !px-2'
                              type='primary'
                              onClick={() => openEditModal(data?.id)}
                              aria-label={`Update bus ${data?.name}`}
                            >
                              Update
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </Skeleton>
        )}
      </div>

      {/* Bus list — pagination */}
      {response?.data?.total_page > 1 && (
        <Pagination
          className='my-5'
          onChange={(e) => setQuery((prevState) => ({ ...prevState, page: e }))}
          total={response?.data?.total_page * 10}
          current={response?.data?.current_page}
        />
      )}

      {/* Edit bus modal component */}
      {editBusModal.open && (
        <EditBusModal
          editBusModal={editBusModal.open}
          setEditBusModal={closeEditModal}
          busId={editBusModal.id}
        />
      )}
    </div>
  );
}

export default React.memo(BusList);
