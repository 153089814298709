/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/extensions */
/* eslint-disable import/extensions */
import React, { useState } from 'react';
import { Button, Card, Tooltip } from 'antd';
import './App.css';
import { VerticalAlignBottomOutlined, VerticalAlignTopOutlined } from '@ant-design/icons';

const SeatStatus = {
  FREE: 'free',
  OCCUPIED: 'occupied',
  SPECIAL: 'special'
};

function Seat({ status = SeatStatus.FREE, onClick }) {
  const getSeatImage = () => {
    if (status === SeatStatus.OCCUPIED) return '/vectors/orange_seat.svg';
    return status === SeatStatus.SPECIAL ? '/vectors/volant_bus.svg' : '/vectors/free_seat.svg';
  };

  const handleClick = () => {
    onClick(status === SeatStatus.OCCUPIED ? SeatStatus.FREE : SeatStatus.OCCUPIED);
  };

  const handleDoubleClick = () => {
    onClick(SeatStatus.SPECIAL);
  };

  return (
    <Tooltip title={status === SeatStatus.SPECIAL ? 'Steering Wheel' : 'Free seat'}>
      <div
        onClick={handleClick}
        onDoubleClick={handleDoubleClick}
        className='seat'
        role='button'
        aria-label='Seat'
      >
        <img src={getSeatImage()} alt='seat' className='seat-image' />
      </div>
    </Tooltip>
  );
}

function Aisle() {
  return <div className='aisle' />;
}

function BusSeatLayout({ seatToExport }) {
  const [seats, setSeats] = useState(Array(7).fill(null).map(() => Array(4).fill(SeatStatus.FREE)));
  const [aislePosition, setAislePosition] = useState(2);

  const toggleSeat = (row, col, newStatus) => {
    const updatedSeats = seats.map((seatRow, rowIndex) => (rowIndex === row ?
      seatRow.map((seat, colIndex) => (colIndex === col ? newStatus : seat)) :
      seatRow));
    setSeats(updatedSeats);
  };

  const addRow = () => setSeats([...seats, Array(seats[0].length).fill(SeatStatus.FREE)]);
  const removeRow = () => seats.length > 3 && setSeats(seats.slice(0, -1));
  const addColumn = () => setSeats(seats.map((row) => [...row, SeatStatus.FREE]));
  const removeColumn = () => seats[0].length > 2 && setSeats(seats.map((row) => row.slice(0, -1)));

  const moveAisleLeft = () => aislePosition > 0 && setAislePosition(aislePosition - 1);
  const moveAisleRight = () => aislePosition < (seats[0].length - 1) + 1 && setAislePosition(aislePosition + 1);

  const exportSeatsConfiguration = () => {
    const busConfiguration = {
      rows: seats.length,
      columns: seats[0].length,
      aislePosition,
      seats: seats.flatMap((row, rowIndex) => row.map((seat, colIndex) => ({
        row: rowIndex,
        column: colIndex,
        status: seat
      })).filter((seat) => seat.status === SeatStatus.OCCUPIED || seat.status === SeatStatus.SPECIAL))
    };
    seatToExport(busConfiguration);
  };

  return (
    <div className='bus-layout'>
      <div className='controls'>
        <Button icon={<VerticalAlignBottomOutlined />} className='text-orange-500 font-bold' onClick={addRow}>Ajouter une rangée</Button>
        <Button icon={<VerticalAlignTopOutlined />} onClick={removeRow} disabled={seats.length <= 3}>Supprimer une rangée</Button>
        <Button icon={<VerticalAlignTopOutlined className='rotate-90' />} className='text-orange-500 font-bold' onClick={addColumn}>Ajouter une colonne</Button>
        <Button icon={<VerticalAlignBottomOutlined className='rotate-90' />} onClick={removeColumn} disabled={seats[0].length <= 2}>Supprimer une colonne</Button>
        <Button onClick={moveAisleLeft} disabled={aislePosition === 0}>Déplacer le couloir à gauche</Button>
        <Button onClick={moveAisleRight} disabled={aislePosition === (seats[0].length - 1) + 1}>Déplacer le couloir à droite</Button>
      </div>
      <Card className='seats'>
        {seats.map((row, rowIndex) => (
          <div className='seat-row' key={rowIndex}>
            {row.slice(0, aislePosition).map((seatStatus, colIndex) => (
              <Seat
                key={colIndex}
                status={seatStatus}
                onClick={(newStatus) => toggleSeat(rowIndex, colIndex, newStatus)}
              />
            ))}
            <Aisle />
            {row.slice(aislePosition).map((seatStatus, colIndex) => (
              <Seat
                key={colIndex + aislePosition}
                status={seatStatus}
                onClick={(newStatus) => toggleSeat(rowIndex, colIndex + aislePosition, newStatus)}
              />
            ))}
          </div>
        ))}
      </Card>
      <Button onClick={exportSeatsConfiguration} className='text-orange-500 font-bold mt-4'>Valider</Button>
    </div>
  );
}

export default BusSeatLayout;
