/* eslint-disable no-underscore-dangle */
/* eslint-disable no-shadow */
import {
  Table, Button, Modal, Form, Input, message, Popconfirm, Skeleton, Result, Select, Tag, Checkbox,
  Switch,
  Tooltip
} from 'antd';
import {
  BellOutlined, MailOutlined, MessageOutlined, AppstoreOutlined,
  PlusOutlined
} from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import React, { useState } from 'react';

import useFetchData from '../../hooks/useFetchData';
import ApiService from '../../utils/apiService';
import { getRoleResponse } from '../../utils/responseAsStatus';
import { reFetchData } from '../../store/slice/appSlice';
import notificationWithIcon from '../../utils/notification';

const { Option } = Select;

function Notification() {
  const dispatch = useDispatch();
  const [notifLoading, notifError, notifResponse] = useFetchData('/all-notification-list');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isUserModalVisible, setIsUserModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [fetchUserLoading, , fetchUserResponse] = useFetchData('/all-users-list');
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [notificationTypes, setNotificationTypes] = useState(['push']);
  const [searchText, setSearchText] = useState('');
  const [selectedToUsers, setSelectedToUsers] = useState([]);
  const [closeAfterSend, setCloseAfterSend] = useState(true);
  const [clearFieldsAfterSend, setClearFieldsAfterSend] = useState(true);

  const handleAddNotification = async (values) => {
    try {
      const notificationData = {
        ...values,
        toUser: values.toUser || [],
        type: notificationTypes
      };

      const response = await ApiService.post('/create-notification', notificationData);
      if (response?.result_code === 0) {
        notificationWithIcon('success', 'SUCCÈS', response?.result?.message || 'Notification créée avec succès');
        if (clearFieldsAfterSend) form.resetFields();
        if (closeAfterSend) setIsModalVisible(true);
        dispatch(reFetchData());
      } else {
        notificationWithIcon('error', 'ERREUR', 'Erreur serveur, veuillez réessayer');
      }
    } catch (error) {
      message.error('Échec de l\'envoi de la notification.');
    }
  };

  const handleDeleteNotification = async (id) => {
    try {
      const res = await ApiService.delete(`/delete-notification/${id}`);
      if (res?.result_code === 0) {
        message.success('Notification supprimée.');
        dispatch(reFetchData());
        setIsUserModalVisible(false);
      } else {
        message.error('Erreur lors de la suppression de la notification.');
      }
    } catch (error) {
      message.error('Échec de la suppression de la notification.');
    }
  };

  const handleSearch = (value) => {
    setSearchText(value);
  };

  const filteredUsers = selectedRoles.length > 0 ?
    fetchUserResponse?.data?.rows?.filter((user) => selectedRoles.includes(user.role)) :
    fetchUserResponse?.data?.rows;

  const searchedUsers = filteredUsers?.filter((user) => {
    const fullName = `${user.name} ${user.firstname}`.toLowerCase();
    return (
      fullName.includes(searchText.toLowerCase()) ||
      user.email.toLowerCase().includes(searchText.toLowerCase())
    );
  });

  const handleRowClick = (record) => {
    setSelectedToUsers(record.toUser || []);
    setIsUserModalVisible(true);
  };

  const columns = [
    {
      title: '',
      key: 'typeIcon',
      render: (record) => {
        const typeIcons = {
          push: <BellOutlined className='m-1' style={{ color: '#1890ff' }} />,
          mail: <MailOutlined className='m-1' style={{ color: '#52c41a' }} />,
          sms: <MessageOutlined className='m-1' style={{ color: '#faad14' }} />,
          app: <AppstoreOutlined className='m-1' style={{ color: '#eb2f96' }} />
        };
        return (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {record.type.map((type) => (
              typeIcons[type] || null
            ))}
          </div>
        );
      }
    },
    {
      title: 'Objet',
      dataIndex: 'subject',
      key: 'subject'
    },
    {
      title: 'Titre',
      dataIndex: 'title',
      key: 'title'
    },
    {
      title: 'Message',
      dataIndex: 'message',
      key: 'message'
    },
    {
      title: 'To User',
      render: (_, record) => (record.toUser ? record.toUser.length : 0),
      key: 'toUser'
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <div className='action-column'>
          <Popconfirm title='Êtes-vous sûr de vouloir supprimer cette notification ?' onConfirm={() => handleDeleteNotification(record._id)} okText='Oui' cancelText='Non'>
            <Button size='small' danger>Supprimer</Button>
          </Popconfirm>
        </div>
      )
    }
  ];

  const handleRoleChange = (selectedRoles) => {
    setSelectedRoles(selectedRoles);
  };

  const handleNotificationTypeChange = (checkedValues) => {
    if (checkedValues.includes('push')) {
      setNotificationTypes(['push']);
    } else {
      setNotificationTypes(checkedValues);
    }
  };

  return (
    <Skeleton loading={notifLoading} active>
      {notifError ? (
        <Result title='Échec de la récupération' subTitle={notifError} status='error' />
      ) : (
        <>
          <Button type='primary' icon={<PlusOutlined />} onClick={() => setIsModalVisible(true)} style={{ marginBottom: 16 }}>
            Ajouter une notification
          </Button>
          <Table
            columns={columns}
            dataSource={notifResponse?.data?.notifications}
            size='small'
            rowKey='_id'
            onRow={(record) => ({
              onClick: (e) => (!e.target.closest('.action-column')) && handleRowClick(record)
            })}
          />
          <Modal
            title={(
              <span>
                Ajouter une notification
                <span className='absolute flex justify-centers right-16 top-4 border py-2 p-3 rounded'>
                  <Tooltip title={!closeAfterSend ? 'Fermer après envoi' : 'Ne pas fermer après envoi'}>
                    <Switch
                      checked={closeAfterSend}
                      onChange={(checked) => setCloseAfterSend(checked)}
                      style={{ marginRight: 8 }}
                      size='small'
                    />
                  </Tooltip>
                  <Tooltip title={!clearFieldsAfterSend ? 'Effacer après envoi' : 'Ne pas effacer après envoi'}>
                    <Switch
                      checked={clearFieldsAfterSend}
                      onChange={(checked) => setClearFieldsAfterSend(checked)}
                      size='small'
                    />
                  </Tooltip>
                </span>
              </span>
              )}
            open={isModalVisible}
            onCancel={() => setIsModalVisible(false)}
            onOk={() => form.submit()}
          >
            <Form form={form} layout='vertical' onFinish={handleAddNotification}>
              <Form.Item
                name='subject'
                label='Objet'
                rules={[{ required: true, message: 'Veuillez entrer l\'objet de la notification.' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name='title'
                label='Titre du message'
                rules={[{ required: true, message: 'Veuillez entrer le titre de la notification.' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name='message'
                label='Message'
                rules={[{ required: true, message: 'Veuillez entrer le message de la notification.' }]}
              >
                <Input.TextArea />
              </Form.Item>

              <Form.Item label='Filtrer par rôle'>
                <Checkbox.Group value={selectedRoles} onChange={handleRoleChange}>
                  {['USER_ROLE', 'STUDENT_ROLE', 'SOCIETY_ROLE', 'ADMIN_ROLE', 'DRIVER_ROLE'].map((role) => (
                    <Checkbox key={role} value={role}>
                      {getRoleResponse(role).label}
                    </Checkbox>
                  ))}
                </Checkbox.Group>
              </Form.Item>

              <Form.Item
                name='toUser'
                label='Utilisateurs destinataires'
                rules={[{ required: true, message: 'Veuillez sélectionner au moins un utilisateur.' }]}
              >
                <Select
                  mode='multiple'
                  placeholder='Sélectionner des utilisateurs'
                  size='large'
                  loading={fetchUserLoading}
                  onSearch={handleSearch}
                  filterOption={false}
                >
                  {searchedUsers?.map((user) => (
                    <Option key={user.id} value={user.id}>
                      <Tag size='small' className='w-[70px] text-center uppercase' color={getRoleResponse(user?.role, true).color}>
                        {getRoleResponse(user?.role).label}
                      </Tag>
                      {`${user.name} ${user.firstname} - ${user.email}`}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name='type'
                label='Choisir la méthode d&#39;envoi'
                rules={[{ required: true, message: 'Choisissez le type de notification.' }]}
              >
                <Checkbox.Group
                  value={notificationTypes}
                  onChange={handleNotificationTypeChange}
                >
                  <Checkbox value='push'>Notification push</Checkbox>
                  <Checkbox value='mail' disabled={notificationTypes.includes('push')}>Email</Checkbox>
                  <Checkbox value='sms' disabled={notificationTypes.includes('push')}>SMS</Checkbox>
                  <Checkbox value='app' disabled={notificationTypes.includes('push')}>Notification In-App</Checkbox>
                </Checkbox.Group>
              </Form.Item>
            </Form>
          </Modal>
          <Modal
            title='Liste des utilisateurs destinataires'
            open={isUserModalVisible}
            onCancel={() => setIsUserModalVisible(false)}
            footer={null}
          >
            <ul>
              {selectedToUsers.map((user) => (
                <li key={user.id}>
                  {`${user.name} ${user.firstname} - ${user.email}`}
                </li>
              ))}
            </ul>
          </Modal>
        </>
      )}
    </Skeleton>
  );
}

export default Notification;
