/* eslint-disable import/no-extraneous-dependencies */
/**
 * @name Hotel Room Booking System
 * @description Hotel Room Booking and Management System Software ~ Developed By Mr. Josia Yvan
 * @copyright ©2024 ― Mr. Josia Yvan. All rights reserved.
 * @version v0.0.1
 */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import 'moment/locale/fr'; // Import the desired locale, e.g., French

import {
  EnvironmentOutlined, PhoneOutlined, UserOutlined
} from '@ant-design/icons';
import {
  Button, DatePicker, Form, Input, Modal, Result, Select
} from 'antd';

import useFetchData from '../../hooks/useFetchData';
import { reFetchData } from '../../store/slice/appSlice';
import ApiService from '../../utils/apiService';
import { getSessionUser, setSessionUserKeyAgainstValue } from '../../utils/authentication';
import notificationWithIcon from '../../utils/notification';
import PageLoader from './PageLoader';

// Set the locale globally
moment.locale('fr'); // or whichever locale you need

function ProfileEditModal({ editProfileModal, setEditProfileModal, user }) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  // fetch user profile API data
  const [fetchLoading, fetchError, fetchResponse] = useFetchData(`/user/${user.id}`);

  // set form data from API data
  useEffect(() => {
    if (fetchResponse) {
      form.setFieldsValue({
        firstname: fetchResponse?.data?.firstname || undefined,
        number: fetchResponse?.data?.number || undefined,
        quartier: fetchResponse?.data?.quartier || undefined,
        role: fetchResponse?.data?.role || undefined,
        registrationDate: fetchResponse?.data?.registrationDate ? moment(fetchResponse?.data?.registrationDate) : undefined,
        expirationDate: fetchResponse?.data?.expirationDate ? moment(fetchResponse?.data?.expirationDate) : undefined,
        payDate: fetchResponse?.data?.payDate ? moment(fetchResponse?.data?.payDate) : undefined,
        isSubscribed: fetchResponse?.data?.isSubscribed !== undefined ? fetchResponse?.data?.isSubscribed.toString() : undefined,
        isFirstSubscription: fetchResponse?.data?.isFirstSubscription !== undefined ? fetchResponse?.data?.isFirstSubscription.toString() : undefined
      });
    }
  }, [fetchResponse, form]);

  // function to handle edit user profile
  const onFinish = (values) => {
    setLoading(true);
    // Convert boolean values from strings
    values.isSubscribed = values.isSubscribed === 'true';
    values.isFirstSubscription = values.isFirstSubscription === 'true';

    ApiService.put(`/user/${user.id}`, values)
      .then((response) => {
        setLoading(false);
        if (response?.result_code === 0) {
          notificationWithIcon('success', 'SUCCESS', response?.result?.message || 'Your profile information updated successfully');
          if (user.id === getSessionUser().id) {
            // update local storage session user data
            setSessionUserKeyAgainstValue('firstname', response?.result?.data?.firstname);
            setSessionUserKeyAgainstValue('quartier', response?.result?.data?.quartier);
            setSessionUserKeyAgainstValue('role', response?.result?.data?.role);
            setSessionUserKeyAgainstValue('number', response?.result?.data?.number);
            setSessionUserKeyAgainstValue('registrationDate', response?.result?.data?.registrationDate);
            setSessionUserKeyAgainstValue('expirationDate', response?.result?.data?.expirationDate);
            setSessionUserKeyAgainstValue('payDate', response?.result?.data?.payDate);
            setSessionUserKeyAgainstValue('isSubscribed', response?.result?.data?.isSubscribed);
            setSessionUserKeyAgainstValue('isFirstSubscription', response?.result?.data?.isFirstSubscription);
          }

          form.resetFields();
          dispatch(reFetchData());
          setEditProfileModal(false);
        } else {
          notificationWithIcon('error', 'ERROR', 'Sorry! Something went wrong. App server error');
        }
      })
      .catch((err) => {
        setLoading(false);
        notificationWithIcon('error', 'ERROR', err?.response?.data?.result?.error?.message || err?.response?.data?.result?.error || 'Sorry! Something went wrong. App server error');
      });
  };

  return (
    <Modal
      title='Edit Profile Information'
      open={editProfileModal}
      onOk={() => setEditProfileModal(false)}
      onCancel={() => setEditProfileModal(false)}
      footer={null}
      width={800}
    >
      {fetchLoading ? (<PageLoader />) : fetchError ? (
        <Result
          title='Failed to fetch'
          subTitle={fetchError}
          status='error'
        />
      ) : (
        <Form
          form={form}
          className='login-form'
          name='edit-profile-form'
          onFinish={onFinish}
          layout='vertical'
        >
          <Form.Item
            label='Role'
            name='role'
            rules={[{ required: true, message: 'Please select One!' }]}
          >
            <Select
              placeholder='Select First role'
              size='large'
              allowClear
            >
              <Select.Option value='ADMIN_ROLE'>ADMIN_ROLE</Select.Option>
              <Select.Option value='USER_ROLE'>USER_ROLE</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label='Prénom'
            name='firstname'
            rules={[{ required: true, message: 'Please input your firstname!' }]}
          >
            <Input
              prefix={<UserOutlined />}
              placeholder='Prénom'
              size='large'
              allowClear
            />
          </Form.Item>

          <Form.Item
            label='Numéro'
            name='number'
            rules={[{ required: true, message: 'Please input your number!' }]}
          >
            <Input
              prefix={<PhoneOutlined />}
              placeholder='Numéro'
              size='large'
              allowClear
              readOnly
            />
          </Form.Item>

          <Form.Item
            label='Quartier'
            name='quartier'
            rules={[{ required: true, message: 'Please select your Quartier!' }]}
          >
            <Input
              prefix={<EnvironmentOutlined />}
              placeholder='Quartier'
              size='large'
              allowClear
            />
          </Form.Item>

          <Form.Item
            label='Abonnement'
            name='isSubscribed'
            rules={[{ required: true, message: 'Please select your Abonnement!' }]}
          >
            <Select
              placeholder='Select Abonnement'
              size='large'
              allowClear
            >
              <Select.Option value='true'>Abonné(e)</Select.Option>
              <Select.Option value='false'>Désabonné(e)</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            label='Date d`abonnement'
            name='payDate'
            rules={[{ required: true, message: 'Please select your subscription Date!' }]}
          >
            <DatePicker
              className='w-full'
              placeholder='Select Date'
              format='YYYY-MM-DD'
              size='large'
              allowClear
            />
          </Form.Item>

          <Form.Item
            label='Date de fin d`abonnement'
            name='expirationDate'
            rules={[{ required: true, message: 'Please select your subscription End Date!' }]}
          >
            <DatePicker
              className='w-full'
              placeholder='Select subscription End Date'
              format='YYYY-MM-DD'
              size='large'
              allowClear
            />
          </Form.Item>

          <Form.Item
            label='Première connexion'
            name='isFirstSubscription'
            rules={[{ required: true, message: 'Please select One!' }]}
          >
            <Select
              placeholder='Select First Subscription'
              size='large'
              allowClear
            >
              <Select.Option value='true'>Oui</Select.Option>
              <Select.Option value='false'>Non</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            label='Date de registre'
            name='registrationDate'
            rules={[{ required: true, message: 'Please select your Register Date!' }]}
          >
            <DatePicker
              className='w-full'
              placeholder='Select Register Date'
              format='YYYY-MM-DD'
              size='large'
              allowClear
            />
          </Form.Item>

          <Form.Item>
            <Button
              type='primary'
              htmlType='submit'
              size='large'
              loading={loading}
              disabled={loading}
            >
              Update Info
            </Button>
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
}

export default React.memo(ProfileEditModal);
