import React, { useState } from 'react';
import {
  Button, Card, Form, Input, notification
} from 'antd';
import ApiService from '../../utils/apiService';

function CreateSpot() {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
      message,
      description
    });
  };

  const onFinish = (values) => {
    setLoading(true);
    ApiService.post('/create-spot', values)
      .then((response) => {
        setLoading(false);
        if (response?.result_code === 0) {
          openNotificationWithIcon('success', 'Succès', 'Spot créé avec succès');
          form.resetFields();
        } else {
          openNotificationWithIcon('error', 'Erreur', 'Erreur lors de la création du spot');
        }
      })
      .catch((error) => {
        setLoading(false);
        openNotificationWithIcon(
          'error',
          'Erreur',
          error?.response?.data?.result?.error || 'Erreur lors de la création du spot'
        );
      });
  };

  return (
    <Form
      form={form}
      name='create-new-spot-form'
      onFinish={onFinish}
      layout='vertical'
    >
      <Card>
        <Form.Item
          label='Nom du lieu'
          name='name'
          rules={[{ required: true, message: 'Veuillez entrer le nom du lieu !' }]}
        >
          <Input placeholder='Nom du lieu' size='large' />
        </Form.Item>

        <Form.Item
          label='Longitude'
          name='longitude'
          rules={[{ required: true, message: 'Veuillez entrer la longitude !' }]}
        >
          <Input placeholder='Longitude' size='large' />
        </Form.Item>

        <Form.Item
          label='Latitude'
          name='latitude'
          rules={[{ required: true, message: 'Veuillez entrer la latitude !' }]}
        >
          <Input placeholder='Latitude' size='large' />
        </Form.Item>

        <Form.Item>
          <Button
            htmlType='submit'
            type='primary'
            size='large'
            loading={loading}
          >
            Ajouter le spot
          </Button>
        </Form.Item>
      </Card>
    </Form>
  );
}

export default CreateSpot;
