import {
  Button, Form, Input
} from 'antd';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { reFetchData } from '../../store/slice/appSlice';
import ApiService from '../../utils/apiService';
import notificationWithIcon from '../../utils/notification';

function CreateOffer() {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  // fonction pour gérer la création d'une nouvelle offre
  const onFinish = (values) => {
    const formdata = {
      title: values.title,
      description: values.description,
      tag: values.tag,
      price: values.price,
      duration: values.duration,
      access: values.access
    };

    setLoading(true);
    ApiService.post('/create-offer', formdata)
      .then((response) => {
        setLoading(false);
        if (response?.result_code === 0) {
          notificationWithIcon('success', 'SUCCÈS', response?.result?.message || 'Nouvelle offre créée avec succès');
          form.resetFields();
          dispatch(reFetchData());
        } else {
          notificationWithIcon('error', 'ERREUR', 'Désolé ! Quelque chose a mal tourné. Erreur du serveur');
        }
      })
      .catch((err) => {
        setLoading(false);
        notificationWithIcon('error', 'ERREUR', err?.response?.data?.result?.error?.message || 'Désolé ! Quelque chose a mal tourné. Erreur du serveur');
      });
  };

  return (
    <div className='flex flex-col items-center p-10 px-4'>
      <div className='bg-[#F5F5F5] p-8 lg:p-12 shadow-md rounded-2xl w-full max-w-4xl'>
        <Form
          form={form}
          className='login-form'
          name='create-new-Offer-form'
          onFinish={onFinish}
          layout='vertical'
        >
          <Form.Item
            label='Titre'
            name='title'
            rules={[{
              required: true,
              message: 'Veuillez saisir le titre de l\'offre !'
            }]}
          >
            <Input
              placeholder='Titre de l`offre'
              size='large'
              allowClear
            />
          </Form.Item>

          <Form.Item
            label='Description'
            name='description'
            rules={[{
              required: true,
              message: 'Veuillez saisir la description de l\'offre !'
            }]}
          >
            <Input.TextArea
              placeholder='Tapez ici la description de l`offre'
              rows={4}
            />
          </Form.Item>

          <Form.Item
            label='Prix'
            name='price'
            rules={[{
              required: true,
              message: 'Veuillez saisir le prix de l\'offre !'
            }]}
          >
            <Input
              placeholder='Prix de l`offre'
              size='large'
              type='text'
              allowClear
            />
          </Form.Item>

          <Form.Item
            label='Étiquette'
            name='tag'
            rules={[{
              required: true,
              message: 'Veuillez saisir le Étiquette de l\'offre !'
            }]}
          >
            <Input
              placeholder='Étiquette de l`offre'
              size='large'
              type='text'
              allowClear
            />
          </Form.Item>

          <Form.Item
            label='Durée (jours)'
            name='duration'
            rules={[{
              required: true,
              message: 'Veuillez saisir la durée !'
            }]}
          >
            <Input
              placeholder='Durée en jours'
              size='large'
              type='number'
              allowClear
            />
          </Form.Item>

          <Form.Item
            label='Limite d`accès'
            name='access'
            rules={[{
              required: true,
              message: 'Veuillez saisir la limite d\'accès !'
            }]}
          >
            <Input
              placeholder='Accès maximum par jour'
              size='large'
              type='number'
              allowClear
            />
          </Form.Item>

          <Form.Item>
            <Button
              className='login-form-button mt-4'
              htmlType='submit'
              type='primary'
              size='large'
              loading={loading}
              disabled={loading}
            >
              Créer une nouvelle offre
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default React.memo(CreateOffer);
