/* eslint-disable no-underscore-dangle */
/* eslint-disable no-shadow */
import React from 'react';
import {
  Button, Descriptions, Modal, Skeleton, Tag, Result,
  Image
} from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import useFetchData from '../../hooks/useFetchData';
import notificationWithIcon from '../../utils/notification';
import ApiService from '../../utils/apiService';
import { reFetchData } from '../../store/slice/appSlice';
import { getBusTagAsResponse } from '../../utils/responseAsStatus';
import SeatView from './SeatView';

const { confirm } = Modal;

function BusDetails({ id }) {
  // fetch Bus details API data
  const [loading, error, response] = useFetchData(`/bus/${id}`);
  const dispatch = useDispatch();

  const handleDeleteBus = (id) => {
    confirm({
      title: 'DELETE Bus',
      icon: <ExclamationCircleFilled />,
      content: 'Are you sure you want to delete this bus permanently?',
      onOk() {
        return new Promise((resolve, reject) => {
          ApiService.delete(`/delete-bus/${id}`)
            .then((res) => {
              if (res?.result_code === 0) {
                notificationWithIcon('success', 'SUCCESS', res?.result?.message || 'Bus deleted successfully');
                dispatch(reFetchData());
                resolve();
              } else {
                notificationWithIcon('error', 'ERROR', 'Sorry! Something went wrong. App server error');
                reject();
              }
            })
            .catch((err) => {
              notificationWithIcon('error', 'ERROR', err?.response?.data?.result?.error?.message || err?.response?.data?.result?.error || 'Sorry! Something went wrong. App server error');
              reject();
            });
        }).catch(() => notificationWithIcon('error', 'ERROR', 'Oops errors!'));
      }
    });
  };

  // todo: la creation determine la position des places dans la bus
  // TODO: afficher les place en temps réel, et son trajet
  // todo: sur l'assignation des bus au trajet, verifier le surallocation des bus

  return (
    <Skeleton loading={loading} paragraph={{ rows: 10 }} active avatar>
      {error ? (
        <Result title='Failed to fetch' subTitle={error} status='error' />
      ) : (
        <>
          <Descriptions
            title='Bus Information'
            bordered
            extra={(
              <Button onClick={() => handleDeleteBus(response?.data?.id)} type='default' danger>
                Delete Bus
              </Button>
            )}
          >
            <Descriptions.Item label='Nom du bus' span={2}>{response?.data?.name}</Descriptions.Item>
            <Descriptions.Item label='Images' span={2}>
              {response?.data?.images && response?.data?.images.map((image) => (
                (
                  <Image
                    key={image.url}
                    className='!w-[100px] !h-[100px]'
                    src={image.url}
                    crossOrigin='anonymous'
                    alt='image'
                  />
                )))}
            </Descriptions.Item>
            <Descriptions.Item label='Conducteur' span={2}>
              {`${response?.data?.driver?.name} ${response?.data?.driver?.firstname}`}
            </Descriptions.Item>
            <Descriptions.Item label='Plaque d`immatriculation' span={2}>
              <Tag color='grey' className='font-bold uppercase'>
                {response?.data?.licensePlate}
              </Tag>
            </Descriptions.Item>
            <Descriptions.Item label='Nombre de places' span={2}>{response?.data?.placeNumber}</Descriptions.Item>
            <Descriptions.Item label='Marque' span={2}>{response?.data?.brand}</Descriptions.Item>
            <Descriptions.Item label='Statut' span={2}>
              <Tag className='text-center uppercase' color={getBusTagAsResponse(response?.data?.tag).color}>{getBusTagAsResponse(response?.data?.tag).label}</Tag>
            </Descriptions.Item>

          </Descriptions>
          <SeatView aislePosition={response?.data?.aislePosition} seatsLayout={response?.data?.seatsLayout} />
        </>
      )}
    </Skeleton>
  );
}

export default React.memo(BusDetails);
