/* eslint-disable jsx-a11y/label-has-associated-control */
import {
  EnvironmentOutlined, LockOutlined, UserOutlined, PhoneOutlined, CalendarOutlined,
  PlusOutlined
} from '@ant-design/icons';
import {
  Button, Card, Form, Input,
  Select,
  Upload
} from 'antd';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { reFetchData } from '../../store/slice/appSlice';
import ApiService from '../../utils/apiService';
import notificationWithIcon from '../../utils/notification';

function CreateUser() {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [fileList, setFileList] = useState([]);
  const [form] = Form.useForm();

  const [isDisponible] = useState([
    { value: 'DRIVER_ROLE', label: 'Chauffeur', disabled: false }
    // { value: 'USER_ROLE', label: 'Utilisateur' },
    // { value: 'STUDENT_ROLE', label: 'Étudiant' },
    // { value: 'SOCIETY_ROLE', label: 'Société' },
    // { value: 'ADMIN_ROLE', label: 'Administrateur' }
  ]);
  const [sexe] = useState([
    { value: 'male', label: 'Homme' },
    { value: 'female', label: 'Femme' }
  ]);

  // function to handle register new user
  const onFinish = (values) => {
    setLoading(true);
    // Create a new FormData object to handle form data and files
    const userFormData = new FormData();
    userFormData.append('identityCard', values.identityCard);
    userFormData.append('name', values.name);
    userFormData.append('firstname', values.firstname);
    userFormData.append('quartier', values.quartier);
    userFormData.append('password', values.password);
    userFormData.append('email', values.email);
    userFormData.append('birthday', values.birthday);
    userFormData.append('birthplace', values.birthplace);
    userFormData.append('identityCard', values.identityCard);
    userFormData.append('address', values.address);
    userFormData.append('district', values.district);
    userFormData.append('occupation', values.occupation);
    userFormData.append('sexe', values.sexe);
    userFormData.append('phone', values.phone);

    // Append files to the FormData object
    if (fileList.length > 1) {
      fileList.forEach((file) => {
        userFormData.append('identityCard_images', file.originFileObj);
      });
    }

    ApiService.post('/create-driver', userFormData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then((response) => {
        setLoading(false);
        if (response?.result_code === 0) {
          notificationWithIcon('success', 'SUCCESS', response?.result?.message || 'New user registration successful');
          form.resetFields();
          dispatch(reFetchData());
        } else {
          notificationWithIcon('error', 'ERROR', 'Sorry! Something went wrong. App server error');
        }
      })
      .catch((err) => {
        setLoading(false);
        const error = err?.response?.data?.result?.error;
        if (typeof error !== 'string') {
          (error?.identityCard !== '') && notificationWithIcon('error', 'ERROR', error?.identityCard || 'Sorry! Something went wrong. App server error');
          (error?.password !== '') && notificationWithIcon('error', 'ERROR', error?.password || 'Sorry! Something went wrong. App server error');
          (error?.firstname !== '') && notificationWithIcon('error', 'ERROR', error?.firstname || 'Sorry! Something went wrong. App server error');
          (error?.name !== '') && notificationWithIcon('error', 'ERROR', error?.name || 'Sorry! Something went wrong. App server error');
          (error?.quartier !== '') && notificationWithIcon('error', 'ERROR', error?.quartier || 'Sorry! Something went wrong. App server error');
        } else {
          notificationWithIcon('error', 'ERROR', error || 'Sorry! Something went wrong. App server error');
        }
      });
  };
  const normFile = (e) => (Array.isArray(e) ? e : e?.fileList);

  return (
    <Form
      form={form}
      className='login-form'
      name='create-new-user'
      onFinish={onFinish}
      layout='vertical'
    >
      <Card>
        <div className='two-grid-column'>
          <Form.Item
            className='w-full md:w-1/2'
            label='Name'
            name='name'
            rules={[{
              required: true,
              message: 'Please input the user name!'
            }]}
          >
            <Input
              prefix={<UserOutlined className='site-form-item-icon' />}
              placeholder='Name'
              size='large'
              type='text'
              allowClear
            />
          </Form.Item>

          <Form.Item
            className='w-full md:w-1/2'
            label='Firstname'
            name='firstname'
            rules={[{
              required: true,
              message: 'Please input the Firstname!'
            }]}
          >
            <Input
              prefix={<UserOutlined className='site-form-item-icon' />}
              placeholder='Firstname'
              size='large'
              type='text'
              allowClear
            />
          </Form.Item>
        </div>

        <div className='two-grid-column'>
          <Form.Item
            className='w-full md:w-1/2'
            label='Quartier'
            name='quartier'
            rules={[{
              required: true,
              message: 'Please input the Quartier!'
            }]}
          >
            <Input
              prefix={<EnvironmentOutlined className='site-form-item-icon' />}
              placeholder='Quartier'
              size='large'
              type='text'
              allowClear
            />
          </Form.Item>

          <Form.Item
            className='w-full md:w-1/2'
            label='Email'
            name='email'
            rules={[{
              required: true,
              type: 'email',
              message: 'Please input a valid Email!'
            }]}
          >
            <Input
              prefix={<UserOutlined className='site-form-item-icon' />}
              placeholder='Email'
              size='large'
              type='email'
              allowClear
            />
          </Form.Item>
        </div>

        <div className='two-grid-column'>
          <Form.Item
            className='w-full md:w-1/2'
            label='Birthday'
            name='birthday'
            rules={[{
              required: true,
              message: 'Please input your Birthday!'
            }]}
          >
            <Input
              prefix={<CalendarOutlined className='site-form-item-icon' />}
              placeholder='Birthday'
              size='large'
              type='date'
              allowClear
            />
          </Form.Item>

          <Form.Item
            className='w-full md:w-1/2'
            label='Birthplace'
            name='birthplace'
            rules={[{
              required: true,
              message: 'Please input your Birthplace!'
            }]}
          >
            <Input
              placeholder='Birthplace'
              size='large'
              type='text'
              allowClear
            />
          </Form.Item>
        </div>

        <div className='two-grid-column'>
          <Form.Item
            className='w-full md:w-1/2'
            label='Identity Card'
            name='identityCard'
            rules={[{
              required: true,
              message: 'Please input your Identity Card!'
            }]}
          >
            <Input
              placeholder='Identity Card'
              size='large'
              type='text'
              allowClear
            />
          </Form.Item>

          <Form.Item
            className='w-full md:w-1/2'
            label='Phone'
            name='phone'
            rules={[{
              required: true,
              message: 'Please input your Phone cin!'
            }]}
          >
            <Input
              prefix={<PhoneOutlined className='site-form-item-icon' />}
              placeholder='Phone'
              size='large'
              type='text'
              allowClear
            />
          </Form.Item>
        </div>

        <div className='two-grid-column'>
          <Form.Item
            className='w-full md:w-1/2'
            label='Address'
            name='address'
            rules={[{
              required: true,
              message: 'Please input your Address!'
            }]}
          >
            <Input
              placeholder='Address'
              size='large'
              type='text'
              allowClear
            />
          </Form.Item>

          <Form.Item
            className='w-full md:w-1/2'
            label='District'
            name='district'
            rules={[{
              required: true,
              message: 'Please input your District!'
            }]}
          >
            <Input
              placeholder='District'
              size='large'
              type='text'
              allowClear
            />
          </Form.Item>
        </div>

        <div className='two-grid-column'>
          <Form.Item
            className='w-full md:w-1/2'
            label='Occupation'
            name='occupation'
            rules={[{
              required: true,
              message: 'Please input your Occupation!'
            }]}
          >
            <Input
              placeholder='Occupation'
              size='large'
              type='text'
              allowClear
            />
          </Form.Item>

          <Form.Item
            className='w-full md:w-1/2'
            label='Rôle'
            name='role'
            rules={[{ required: true, message: 'Veuillez sélectionner le rôle!' }]}
          >
            <Select
              placeholder='-- sélectionner le rôle --'
              optionFilterProp='children'
              options={isDisponible}
              size='large'
              allowClear
            />
          </Form.Item>
        </div>

        <div className='two-grid-column'>
          <Form.Item
            className='w-full md:w-1/2'
            label='Password'
            name='password'
            rules={[{
              required: true,
              message: 'Please input your Password!'
            }]}
            hasFeedback
          >
            <Input.Password
              prefix={<LockOutlined className='site-form-item-icon' />}
              placeholder='Password'
              size='large'
              type='password'
              allowClear
            />
          </Form.Item>

          <Form.Item
            className='w-full md:w-1/2'
            label='Confirmer le mot de passe'
            name='confirmPassword'
            dependencies={['password']}
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Veuillez confirmer votre mot de passe!'
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Les mots de passe ne correspondent pas!'));
                }
              })
            ]}
          >
            <Input.Password
              placeholder='Confirmer le mot de passe'
              size='large'
              allowClear
            />
          </Form.Item>
        </div>

        <div className='two-grid-column'>
          <Form.Item
            className='w-full md:w-1/2'
            label='Identity Card Number'
            name='identityCard'
            rules={[{
              required: true,
              message: 'Please input the user cin!'
            }]}
          >
            <Input
              prefix={<UserOutlined className='site-form-item-icon' />}
              placeholder='CIN'
              size='large'
              type='text'
              allowClear
            />
          </Form.Item>

          <Form.Item
            className='w-full md:w-1/2'
            label='Sexe'
            name='sexe'
            rules={[{ required: true, message: 'Veuillez sélectionner le sexe!' }]}
          >
            <Select
              placeholder='-- sélectionner le sexe --'
              options={sexe}
              size='large'
              allowClear
            />
          </Form.Item>
        </div>
        <div className='two-grid-column'>

          <Form.Item
            name='cin'
            label='CIN (recto-verso)'
            valuePropName='file'
            rules={[{ required: true, message: '2 images sont requis!' }]}
          >
            <Upload
              listType='picture-card'
              getValueFromEvent={normFile}
              onChange={({ fileList: newFileList }) => setFileList(newFileList)}
              accept='.jpg,.jpeg,.png,.pdf'
              beforeUpload={() => false}
              fileList={fileList}
              name='room_images'
              maxCount={5}
            >
              {fileList.length >= 2 ? null : (
                <div>
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>Upload</div>
                </div>
              )}
            </Upload>
          </Form.Item>

          <Form.Item>
            <Button
              type='primary'
              htmlType='submit'
              className='login-form-button'
              loading={loading}
              size='large'
            >
              Valider
            </Button>
          </Form.Item>
        </div>
      </Card>
    </Form>
  );
}

export default CreateUser;
