/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react';
import { v4 as uniqueId } from 'uuid';
import {
  Button, Empty, Pagination, Result, Skeleton, Modal
} from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import useFetchData from '../../hooks/useFetchData';
import QueryOptions from '../shared/QueryOptions';
import { formatDateInFrench } from '../../utils/format';
import notificationWithIcon from '../../utils/notification';
import { reFetchData } from '../../store/slice/appSlice';
import ApiService from '../../utils/apiService';

const { confirm } = Modal;

function SubscriptionList() {
  const dispatch = useDispatch();
  const [query, setQuery] = useState({
    search: '', sort: 'asce', page: '1', rows: '10'
  });

  // fetch Subscription-list API data
  const [loading, error, response] = useFetchData(`/all-subscriptions-list?keyword=${query.search}&limit=${query.rows}&page=${query.page}&sort=${query.sort}`);

  // reset query options
  useEffect(() => {
    setQuery((prevState) => ({ ...prevState, page: '1' }));
  }, [query.rows, query.search]);

  const handleDeleteOffer = (_id) => {
    confirm({
      title: 'DELETE Subscription',
      icon: <ExclamationCircleFilled />,
      content: 'Are you sure you want to delete this subscription permanently?',
      onOk() {
        return new Promise((resolve, reject) => {
          ApiService.delete(`/delete-subscription/${_id}`)
            .then((res) => {
              if (res?.result_code === 0) {
                notificationWithIcon('success', 'SUCCESS', res?.result?.message || 'Offer deleted successfully');
                dispatch(reFetchData());
                resolve();
              } else {
                notificationWithIcon('error', 'ERROR', 'Sorry! Something went wrong. App server error');
                reject();
              }
            })
            .catch((err) => {
              notificationWithIcon('error', 'ERROR', err?.response?.data?.result?.error?.message || err?.response?.data?.result?.error || 'Sorry! Something went wrong. App server error');
              reject();
            });
        }).catch(() => notificationWithIcon('error', 'ERROR', 'Oops errors!'));
      }
    });
  };

  return (
    <div>
      {/* Subscription list ― query section */}
      <QueryOptions query={query} setQuery={setQuery} />

      {/* Subscription list ― content section */}
      <div className='w-full flex flex-row flex-wrap items-center justify-center gap-2'>
        {error ? (
          <Result
            title='Failed to fetch'
            subTitle={error}
            status='error'
          />
        ) : (
          <Skeleton loading={loading} paragraph={{ rows: 10 }} active>
            {response?.data?.rows?.length === 0 ? (
              <Empty
                className='mt-10'
                description={<span>Sorry! No data found.</span>}
              />
            ) : (
              <div className='table-layout'>
                <div className='table-layout-container'>
                  <table className='data-table'>
                    {/* data table ― head */}
                    <thead className='data-table-head'>
                      <tr className='data-table-head-tr'>
                        <th className='data-table-head-tr-th text-center' scope='col'>
                          Utilisateur
                        </th>
                        <th className='data-table-head-tr-th text-center' scope='col'>
                          Offre
                        </th>
                        <th className='data-table-head-tr-th text-center' scope='col'>
                          Date d'abonnement
                        </th>
                        <th className='data-table-head-tr-th text-center' scope='col'>
                          Date d'expiration
                        </th>
                        <th className='data-table-head-tr-th text-center' scope='col'>
                          Actions
                        </th>
                      </tr>
                    </thead>

                    {/* data table ― body */}
                    <tbody>
                      {response?.data?.rows?.map((data) => (
                        <tr className='data-table-body-tr' key={uniqueId()}>
                          <td className='data-table-body-tr-td text-center'>
                            {data?.user.email}
                          </td>
                          <td className='data-table-body-tr-td text-center'>
                            {data?.offer.title}
                          </td>
                          <td className='data-table-body-tr-td text-center'>
                            {formatDateInFrench(data?.subscriptionDate)}
                          </td>
                          <td className='data-table-body-tr-td text-center'>
                            {formatDateInFrench(data?.expiredSubscriptionDate)}
                          </td>
                          <td className='data-table-body-tr-td text-center'>
                            <Button
                              className='inline-flex items-center !px-2'
                              danger
                              onClick={() => handleDeleteOffer(data?.id)}
                            >
                              Delete
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </Skeleton>
        )}
      </div>

      {/* Subscription list ― pagination */}
      {response?.data?.total_page > 1 && (
        <Pagination
          className='my-5'
          onChange={(e) => setQuery((prevState) => ({ ...prevState, page: e }))}
          total={response?.data?.total_page * 10}
          current={response?.data?.current_page}
        />
      )}
    </div>
  );
}

export default React.memo(SubscriptionList);
