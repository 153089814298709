/**
 * @name Busnay
 * @author Mr. Josia Yvan
 * @description System API and Management System Software ~ Developed By Mr. Josia Yvan
 * @copyright ©2024 ― Mr. Josia Yvan. All rights reserved.
 * @version v0.0.1
 *
 */
import {
  BookFilled,
  BugOutlined,
  DashboardFilled, FullscreenExitOutlined, FullscreenOutlined, GiftFilled, LogoutOutlined, NotificationOutlined, TabletOutlined, TeamOutlined, PartitionOutlined,
  WalletFilled,
  CarFilled
} from '@ant-design/icons';
import {
  Button, Layout, Menu, Modal, Tooltip
} from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Logo from '../assets/images/logo.png';
import UserBox from '../components/shared/UserBox';
import Dashboard from '../components/tabs/Dashboard';
import Users from '../components/tabs/Users';
import useFullScreen from '../hooks/useFullScreen';
import ApiService from '../utils/apiService';
import { removeSessionAndLogoutUser } from '../utils/authentication';
import notificationWithIcon from '../utils/notification';
import Offer from '../components/tabs/Offer';
import WalletList from '../components/tabs/Wallet';
import Infoline from '../components/home/Infoline';
import Logger from '../components/home/Logger';
import Notification from '../components/home/Notification';
import Subscription from '../components/tabs/Subscription';
import Bus from '../components/tabs/Bus';
import Trajet from '../components/tabs/Trajet';

const {
  Header, Content, Footer, Sider
} = Layout;

function Main() {
  window.document.title = 'Administrateur Busnay — Main';
  const { isFullscreen, toggleFullScreen } = useFullScreen();
  const [selectedKeys, setSelectedKeys] = useState('1');
  const navigate = useNavigate();
  const { tab } = useParams();

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showLogoutConfirm = () => {
    setIsModalVisible(true);
  };

  const handleLogout = async () => {
    try {
      const response = await ApiService.post('/auth/logout');
      if (response?.result_code === 0) {
        removeSessionAndLogoutUser();
      } else {
        notificationWithIcon('error', 'ERROR', 'Sorry! Something went wrong. App server error');
        removeSessionAndLogoutUser();
      }
    } catch (error) {
      notificationWithIcon('error', 'ERROR', error?.response?.data?.result?.error || 'Sorry! Something went wrong. App server error');
      removeSessionAndLogoutUser();
    }
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleTabChange = (key) => {
    switch (key) {
      case '1': {
        navigate('/main/dashboard');
        break;
      }
      case '2': {
        navigate('/main/users');
        break;
      }
      case '3': {
        navigate('/main/offer');
        break;
      }
      case '4': {
        navigate('/main/wallet');
        break;
      }
      case '5': {
        navigate('/main/trajet');
        break;
      }
      case '6': {
        navigate('/main/subscription');
        break;
      }
      case '7': {
        navigate('/main/contact');
        break;
      }
      case '8': {
        navigate('/main/logs');
        break;
      }
      case '9': {
        navigate('/main/notification');
        break;
      }
      case '11': {
        navigate('/main/bus');
        break;
      }
      case '10': {
        showLogoutConfirm();
        break;
      }
      default: {
        navigate('/main/dashboard');
      }
    }
  };

  useEffect(() => {
    if (tab) {
      switch (tab) {
        case 'dashboard': {
          setSelectedKeys('1');
          break;
        }
        case 'users': {
          setSelectedKeys('2');
          break;
        }
        case 'offer': {
          setSelectedKeys('3');
          break;
        }
        case 'wallet': {
          setSelectedKeys('4');
          break;
        }
        case 'trajet': {
          setSelectedKeys('5');
          break;
        }
        case 'subscription': {
          setSelectedKeys('6');
          break;
        }
        case 'contact': {
          setSelectedKeys('7');
          break;
        }
        case 'logs': {
          setSelectedKeys('8');
          break;
        }
        case 'notification': {
          setSelectedKeys('9');
          break;
        }
        case 'bus': {
          setSelectedKeys('11');
          break;
        }
        case 'logout': {
          setSelectedKeys('10');
          break;
        }
        default: {
          navigate('/not-found');
        }
      }
    }
  }, [tab, navigate]);

  useEffect(() => {
    switch (selectedKeys) {
      case '1': {
        window.document.title = 'Administrateur Busnay — Dashboard';
        break;
      }
      case '2': {
        window.document.title = 'Administrateur Busnay — Utilisateur';
        break;
      }
      case '3': {
        window.document.title = 'Administrateur Busnay — Offre';
        break;
      }
      case '4': {
        window.document.title = 'Administrateur Busnay — Porte-feuille';
        break;
      }
      case '5': {
        window.document.title = 'Administrateur Busnay — Trajet';
        break;
      }
      case '6': {
        window.document.title = 'Administrateur Busnay — Abonnement';
        break;
      }
      case '7': {
        window.document.title = 'Administrateur Busnay — Contact';
        break;
      }
      case '8': {
        window.document.title = 'Administrateur Busnay — Logger';
        break;
      }
      case '9': {
        window.document.title = 'Administrateur Busnay — Notification';
        break;
      }
      case '10': {
        window.document.title = 'Administrateur Busnay — Logout';
        break;
      }
      case '11': {
        window.document.title = 'Administrateur Busnay — Bus';
        break;
      }
      default: {
        window.document.title = 'Administrateur Busnay — Dashboard';
      }
    }
  }, [selectedKeys]);

  return (
    <Layout className='w-full h-screen'>
      <Sider breakpoint='lg'>
        <UserBox />

        <Menu
          theme='dark'
          mode='inline'
          selectedKeys={[selectedKeys]}
          onClick={(e) => {
            handleTabChange(e.key);
          }}
          items={[
            {
              key: '1',
              icon: <DashboardFilled />,
              label: 'Dashboard'
            },
            {
              key: '2',
              icon: <TeamOutlined />,
              label: 'Utilisateur'
            },
            {
              key: '3',
              icon: <GiftFilled />,
              label: 'Offre'
            },
            {
              key: '4',
              icon: <WalletFilled />,
              label: 'Porte-feuille'
            },
            {
              key: '5',
              icon: <PartitionOutlined />,
              label: 'Trajet'
            },
            {
              key: '6',
              icon: <BookFilled />,
              label: 'Abonnement'
            },
            {
              key: '11',
              icon: <CarFilled />,
              label: 'Bus'
            },
            {
              key: '7',
              icon: <TabletOutlined />,
              label: 'Infoline'
            },
            {
              key: '8',
              icon: <BugOutlined />,
              label: 'Logger'
            },
            {
              key: '9',
              icon: <NotificationOutlined />,
              label: 'Notification'
            },
            {
              key: '10',
              icon: <LogoutOutlined />,
              label: 'Logout'
            }
          ]}
        />
      </Sider>

      <Layout>
        <Header className='p-0 !bg-bg-white'>
          <Link to='/main/dashboard'>
            <img
              className='h-[65px] mx-auto'
              alt='beach-resort-logo'
              src={Logo}
            />
          </Link>

          {/* full screen toggle button */}
          <Tooltip title='Click to toggle Full Screen' placement='left'>
            <Button
              className='absolute right-5 top-5'
              icon={isFullscreen ?
                (<FullscreenExitOutlined className='' />) :
                (<FullscreenOutlined className='' />)}
              onClick={toggleFullScreen}
              shape='default'
              type='default'
              size='middle'
            />
          </Tooltip>
        </Header>

        <Content className='bg-bg-white overflow-y-scroll m-2 p-2'>
          {selectedKeys === '1' && (<Dashboard />)}
          {selectedKeys === '2' && (<Users />)}
          {selectedKeys === '3' && (<Offer />)}
          {selectedKeys === '4' && (<WalletList />)}
          {selectedKeys === '5' && (<Trajet />)}
          {selectedKeys === '6' && (<Subscription />)}
          {selectedKeys === '7' && (<Infoline />)}
          {selectedKeys === '8' && (<Logger />)}
          {selectedKeys === '9' && (<Notification />)}
          {selectedKeys === '11' && (<Bus />)}
        </Content>
        <Modal
          title='Are you sure you want to log out?'
          open={isModalVisible}
          onOk={handleLogout}
          onCancel={handleCancel}
          okText='Yes, Log Out'
          cancelText='Cancel'
          okType='danger'
        >
          <p>All unsaved changes will be lost.</p>
        </Modal>
        <Footer className='text-center font-text-font font-medium '>
          ©2024 Administrateur Busnay — Developed By
          {' '}
          <a
            className='text-color-primary hover:text-color-secondary'
            href='https://www.linkedin.com/in/josia-yvan/'
            target='_blank'
            rel='noreferrer'
          >
            Mr. Josia Yvan
          </a>
        </Footer>
      </Layout>
    </Layout>
  );
}

export default React.memo(Main);
