/* eslint-disable no-underscore-dangle */
import {
  Button, Card, Form, Input, Select, Alert, DatePicker
} from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { reFetchData } from '../../store/slice/appSlice';
import ApiService from '../../utils/apiService';
import notificationWithIcon from '../../utils/notification';
import useFetchData from '../../hooks/useFetchData';

const { Option } = Select;

function CreateTrajet() {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [fetchBusLoading, fetchBusError, fetchBusResponse] = useFetchData('/all-buses-list');
  const [fetchSpotsLoading, fetchSpotsError, fetchSpotsResponse] = useFetchData('/all-spots-list');

  const [spots, setSpots] = useState([]); // Variable d'état pour stocker les spots

  useEffect(() => {
    if (fetchSpotsResponse?.data?.rows) {
      const initialSpots = fetchSpotsResponse.data.rows.map((spot) => ({
        value: spot._id,
        label: spot.name,
        disabled: false
      }));
      setSpots(initialSpots);
    }
  }, [fetchSpotsResponse]);

  // Fonction pour gérer la création d'un nouveau trajet
  const onFinish = (values) => {
    setLoading(true);
    ApiService.post('/create-trajet', values)
      .then((response) => {
        setLoading(false);
        if (response?.result_code === 0) {
          notificationWithIcon('success', 'SUCCÈS', response?.result?.message || 'Nouveau trajet créé avec succès');
          form.resetFields();
          dispatch(reFetchData());
        } else {
          notificationWithIcon('error', 'ERREUR', 'Désolé ! Quelque chose a mal tourné. Erreur du serveur');
        }
      })
      .catch((err) => {
        setLoading(false);
        notificationWithIcon('error', 'ERREUR', err?.response?.data?.result?.error?.message || 'Désolé ! Quelque chose a mal tourné. Erreur du serveur');
      });
  };

  const handleSpotChange = (value, field) => {
    // Réactive tous les spots au début
    const updatedSpots = spots.map((spot) => ({ ...spot, disabled: false }));

    // Désactive le spot sélectionné
    const selectedSpot = updatedSpots.find((spot) => spot.value === value);
    if (selectedSpot) {
      selectedSpot.disabled = true;
    }

    // Met à jour les spots
    setSpots(updatedSpots);

    // Si le champ est vidé, réactive le spot
    if (!value) {
      updatedSpots.forEach((spot) => {
        if (spot.value === value) {
          spot.disabled = false; // Réactive le spot
        }
      });
    }

    // Met à jour la sélection dans le formulaire
    form.setFieldsValue({ [field]: value });
  };

  return (
    <Form
      form={form}
      name='create-new-trajet-form'
      onFinish={onFinish}
      layout='vertical'
    >
      <Card>
        {fetchBusError && <Alert message='Erreur de chargement des bus' type='error' />}
        {fetchSpotsError && <Alert message='Erreur de chargement des spots' type='error' />}

        <div className='two-grid-column'>
          <Form.Item
            label='Bus'
            className='w-full md:w-1/2'
            name='bus'
            rules={[{ required: true, message: 'Veuillez sélectionner un bus !' }]}
          >
            <Select
              placeholder='-- Sélectionner un bus --'
              size='large'
              allowClear
              loading={fetchBusLoading}
            >
              {fetchBusResponse?.data?.rows?.map((bus) => (
                <Option key={bus.id} value={bus.id}>
                  <span className='font-bold'>{bus.name}</span>
                  {' '}
                  [
                  {bus.licensePlate}
                  ]
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label='Point de départ'
            className='w-full md:w-1/2'
            name='departure'
            rules={[{ required: true, message: 'Veuillez sélectionner un point de départ !' }]}
          >
            <Select
              placeholder='-- Sélectionner un point de départ --'
              size='large'
              allowClear
              loading={fetchSpotsLoading}
              onChange={(value) => handleSpotChange(value, 'departure')}
            >
              {spots.map((spot) => (
                <Option key={spot.value} value={spot.value} disabled={spot.disabled}>
                  {spot.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <div className='two-grid-column'>
          <Form.Item
            label='Heure de départ'
            className='w-full md:w-1/2'
            name='departureTime'
            rules={[{ required: true, message: 'Veuillez saisir l`heure de départ !' }]}
          >
            <Input placeholder='Heure de départ' size='large' type='time' allowClear />
          </Form.Item>
          <Form.Item
            label='Point d`arrivée'
            className='w-full md:w-1/2'
            name='arrival'
            rules={[{ required: true, message: 'Veuillez sélectionner un point d`arrivée !' }]}
          >
            <Select
              placeholder='-- Sélectionner un point d`arrivée --'
              size='large'
              allowClear
              loading={fetchSpotsLoading}
              onChange={(value) => handleSpotChange(value, 'arrival')}
            >
              {spots.map((spot) => (
                <Option key={spot.value} value={spot.value} disabled={spot.disabled}>
                  {spot.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <div className='two-grid-column'>
          <Form.Item
            label='Temps estimé (en minutes)'
            className='w-full md:w-1/2'
            name='estimationTime'
            rules={[{ required: true, message: 'Veuillez saisir le temps estimé du trajet !' }]}
          >
            <Input placeholder='Temps estimé' size='large' type='number' allowClear />
          </Form.Item>
          <Form.Item
            label='Distance (en km)'
            className='w-full md:w-1/2'
            name='distance'
            rules={[{ required: true, message: 'Veuillez saisir la distance du trajet !' }]}
          >
            <Input placeholder='Distance' size='large' type='number' allowClear />
          </Form.Item>
        </div>
        <div className='two-grid-column'>
          <Form.Item
            label='Date de départ'
            className='w-full md:w-1/2'
            name='date'
            rules={[{ required: true, message: 'Veuillez sélectionner une date de départ !' }]}
          >
            <DatePicker
              placeholder='-- Sélectionner une date de départ --'
              size='large'
              className='w-[98.5%]'
              allowClear
            />
          </Form.Item>
          <Form.Item className='float-right'>
            <Button
              htmlType='submit'
              type='primary'
              size='large'
              loading={loading}
              disabled={loading}
            >
              Valider
            </Button>
          </Form.Item>
        </div>
      </Card>
    </Form>
  );
}

export default React.memo(CreateTrajet);
