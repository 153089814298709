import React from 'react';
import {
  Button, Descriptions, Modal, Skeleton, Tag, Result
} from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import useFetchData from '../../hooks/useFetchData';
import notificationWithIcon from '../../utils/notification';
import ApiService from '../../utils/apiService';
import { reFetchData } from '../../store/slice/appSlice';

const { confirm } = Modal;

function SubscriptionDetails({ id }) {
  // fetch Subscription details API data
  const [loading, error, response] = useFetchData(`/get-subscription/${id}`);
  const dispatch = useDispatch();

  const handleDeleteSubscription = (_id) => {
    confirm({
      title: 'DELETE Subscription',
      icon: <ExclamationCircleFilled />,
      content: 'Are you sure you want to delete this Subscription permanently?',
      onOk() {
        return new Promise((resolve, reject) => {
          ApiService.delete(`/delete-subscription/${_id}`)
            .then((res) => {
              if (res?.result_code === 0) {
                notificationWithIcon('success', 'SUCCESS', res?.result?.message || 'Subscription deleted successfully');
                dispatch(reFetchData());
                resolve();
              } else {
                notificationWithIcon('error', 'ERROR', 'Sorry! Something went wrong. App server error');
                reject();
              }
            })
            .catch((err) => {
              notificationWithIcon('error', 'ERROR', err?.response?.data?.result?.error?.message || err?.response?.data?.result?.error || 'Sorry! Something went wrong. App server error');
              reject();
            });
        }).catch(() => notificationWithIcon('error', 'ERROR', 'Oops errors!'));
      }
    });
  };

  return (
    <Skeleton loading={loading} paragraph={{ rows: 10 }} active avatar>
      {error ? (
        <Result title='Failed to fetch' subTitle={error} status='error' />
      ) : (
        <Descriptions
          title='Subscription Information'
          bordered
          extra={(
            <Button onClick={() => handleDeleteSubscription(response?.data?.id)} type='default' danger>
              Delete Subscription
            </Button>
          )}
        >
          <Descriptions.Item label='Title' span={2}>{response?.data?.title}</Descriptions.Item>
          <Descriptions.Item label='Description' span={2}>{response?.data?.description}</Descriptions.Item>
          <Descriptions.Item label='Status' span={2}>
            <Tag className='text-center uppercase' span={2} color={response?.data?.status === 'active' ? 'lime-inverse' : 'default'}>{response?.data?.status}</Tag>
          </Descriptions.Item>
          <Descriptions.Item label='Price' span={2}>
            {response?.data?.price}
            {' '}
            USD
          </Descriptions.Item>
          <Descriptions.Item label='Duration' span={2}>
            {`${response?.data?.duration} Month${response?.data?.duration > 1 ? 's' : ''}`}
          </Descriptions.Item>
          <Descriptions.Item label='Access Limit' span={2}>
            {response?.data?.access_limit}
            {' '}
            per month
          </Descriptions.Item>
        </Descriptions>
      )}
    </Skeleton>
  );
}

export default React.memo(SubscriptionDetails);
