/* eslint-disable no-unused-vars */
import {
  Button, Card, Form, Input, Select, Upload, Modal
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { reFetchData } from '../../store/slice/appSlice';
import ApiService from '../../utils/apiService';
import notificationWithIcon from '../../utils/notification';
import useFetchData from '../../hooks/useFetchData';
import BusSeatLayout from './BusSeatLayout';

const { Option } = Select;

function CreateBus() {
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedSeats, setSelectedSeats] = useState({});
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [fetchLoading, , fetchResponse] = useFetchData('/get-users-by?role=DRIVER_ROLE');

  const tagOptions = [
    { value: 'on_way', label: 'En route', disabled: true },
    { value: 'pickup', label: 'Ramassage', disabled: true },
    { value: 'drops_off', label: 'Dépôt', disabled: true },
    { value: 'parking', label: 'Parking', disabled: false }
  ];

  const onFinish = async (values) => {
    if (Object.keys(selectedSeats).length === 0) {
      return notificationWithIcon('error', 'ERREUR', 'Veuillez spécifier la disposition des sièges');
    }

    const busFormData = new FormData();
    busFormData.append('driver', values.driver);
    busFormData.append('name', values.name);
    busFormData.append('licensePlate', values.licensePlate);
    busFormData.append('placeNumber', values.placeNumber);
    busFormData.append('brand', values.brand);
    busFormData.append('tag', values.tag);
    busFormData.append('seatsLayout', JSON.stringify(selectedSeats.seats));
    busFormData.append('aislePosition', selectedSeats.aislePosition);

    if (fileList.length > 0) {
      fileList.forEach((file) => {
        busFormData.append('images', file.originFileObj);
      });
    }

    setLoading(true);
    try {
      const response = await ApiService.post('/create-bus', busFormData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      setLoading(false);
      if (response?.result_code === 0) {
        notificationWithIcon('success', 'SUCCÈS', response?.result?.message || 'Nouveau bus créé avec succès');
        form.resetFields();
        setFileList([]);
        setSelectedSeats([]);
        dispatch(reFetchData());
      } else {
        notificationWithIcon('error', 'ERREUR', 'Désolé ! Quelque chose a mal tourné. Erreur du serveur');
      }
    } catch (err) {
      setLoading(false);
      notificationWithIcon('error', 'ERREUR', err?.response?.data?.result?.error?.message || 'Désolé ! Quelque chose a mal tourné. Erreur du serveur');
    }
  };

  const normFile = (e) => (Array.isArray(e) ? e : e?.fileList);

  const showModal = () => setModalVisible(true);
  const handleCancel = () => setModalVisible(false);

  const handleSeatSelection = (seats) => setSelectedSeats(seats);

  return (
    <>
      <Form
        form={form}
        name='create-new-bus-form'
        onFinish={onFinish}
        layout='vertical'
      >
        <Card>
          <div className='two-grid-column'>
            <Form.Item
              label='Nom du bus'
              className='w-full md:w-1/2'
              name='name'
              rules={[{ required: true, message: 'Veuillez saisir le nom du bus !' }]}
            >
              <Input placeholder='Nom du bus' size='large' allowClear />
            </Form.Item>
            <Form.Item
              label='Conducteur'
              className='w-full md:w-1/2'
              name='driver'
              rules={[{ required: true, message: 'Veuillez sélectionner un conducteur !' }]}
            >
              <Select
                placeholder='-- Sélectionner un conducteur --'
                size='large'
                allowClear
                loading={fetchLoading}
              >
                {fetchResponse?.data?.rows?.map((driver) => (
                  <Option key={driver.id} value={driver.id}>
                    {`${driver.name} ${driver.firstname} - ${driver.address}`}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div className='two-grid-column'>
            <Form.Item
              label='Plaque d`immatriculation'
              className='w-full md:w-1/2'
              name='licensePlate'
              rules={[{ required: true, message: 'Veuillez saisir la plaque d\'immatriculation !' }]}
            >
              <Input placeholder='Plaque d`immatriculation' size='large' allowClear />
            </Form.Item>
            <Form.Item
              label='Nombre de places'
              className='w-full md:w-1/2'
              name='placeNumber'
              rules={[{ required: true, message: 'Veuillez saisir le nombre de places !' }]}
            >
              <Input placeholder='Nombre de places' size='large' type='number' allowClear />
            </Form.Item>
          </div>
          <div className='two-grid-column'>
            <Form.Item
              label='Marque'
              className='w-full md:w-1/2'
              name='brand'
              rules={[{ required: true, message: 'Veuillez saisir la marque du bus !' }]}
            >
              <Input placeholder='Marque du bus' size='large' allowClear />
            </Form.Item>
            <Form.Item
              label='Statut'
              className='w-full md:w-1/2'
              name='tag'
              rules={[{ required: true, message: 'Veuillez sélectionner une statut pour le bus !' }]}
            >
              <Select
                placeholder='-- Sélectionner une statut --'
                size='large'
                allowClear
                options={tagOptions}
              />
            </Form.Item>
          </div>
          <div className='two-grid-column'>
            <Form.Item
              name='images'
              className='w-full md:w-1/2'
              label='Images'
              valuePropName='file'
              rules={[{ required: true, message: 'Veuillez uploader 1 à 5 images !' }]}
            >
              <Upload
                listType='picture-card'
                getValueFromEvent={normFile}
                onChange={({ fileList: newFileList }) => setFileList(newFileList)}
                accept='.jpg,.jpeg,.png,.pdf'
                beforeUpload={() => false}
                fileList={fileList}
                maxCount={5}
              >
                {fileList.length < 5 && (
                  <div>
                    <PlusOutlined />
                    <div style={{ marginTop: 8 }}>Upload</div>
                  </div>
                )}
              </Upload>
            </Form.Item>
            <Form.Item className='w-full md:w-1/2'>
              <Button type='default' onClick={showModal}>
                Modifier la disposition des sièges
              </Button>
              {selectedSeats.length > 0 && (
                <div>
                  <strong>Sélectionné :</strong>
                  {' '}
                  {selectedSeats.join(', ')}
                </div>
              )}
            </Form.Item>
            <Form.Item className='float-right'>
              <Button
                htmlType='submit'
                type='primary'
                size='large'
                loading={loading}
                disabled={loading}
              >
                Valider
              </Button>
            </Form.Item>
          </div>
        </Card>
      </Form>

      <Modal
        title='Disposition des sièges'
        open={modalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <BusSeatLayout seatToExport={handleSeatSelection} />
      </Modal>
    </>
  );
}

export default CreateBus;
